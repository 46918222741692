import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/itar/Search.vue"
import Create from "../views/itar/Create.vue"
import Details from "../views/itar/Details.vue"
import Denied from "../views/Denied.vue"
import store from "../store"

Vue.use(VueRouter)

const prefix = "/itar/"

const routes = [
  {
    path: prefix,
    name: "Itar",
    component: store.getters["auth/canViewItarRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Itar_Search",
    component: store.getters["auth/canViewItarRecord"] ? Search : Denied,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Itar_Create",
    component: store.getters["auth/canEditItarRecord"] ? Create : Denied,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Itar_Details",
    component: store.getters["auth/canViewItarRecord"] ? Details : Denied,
  },
]

export default routes
