<script>
import {mapState} from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import AdminSearchResults from "@/components/global/AdminSearchResults"
import _ from "underscore"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    AdminSearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Vendor Search",
      collapseRows: true,
      cellCap: 2,
      vendors: [],
      filters: [
        {
          id: "vendorTextSearch",
          type: "text",
          value: "",
          cols: 6,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },
      ],
      sortBy: "vendorId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("auth", ["role", "email"]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
    results() {
      return {
        fields: [
          {key: "vendorId", label: "ID", sortable: true},
          {key: "vendorName", label: "Vendor Name", sortable: true},
          {
            key: "vendorContactId",
            label: "Vendor Contact",
            sortable: true,
            formatter: (value, key, item) => {
              if (item.contactFullName) {
                return `${item.contactFullName}`
              }
            },
          },
          {key: "vendorUrl", label: "Vendor URL", sortable: true},
          {key: "vendorComments", label: "Comments", sortable: true},
          {key: "vendorActive", label: "Active", sortable: true},
          {key: "phoneNumber", label: "Vendor Phone", sortable: true},
        ],
        records: this.vendors,
      }
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    async initialSearch() {
      try {
        const vendors = await this.$store.dispatch("asset/getVendors")
        console.log(vendors)
        this.vendors = vendors.map(vendor => ({
          vendorId: vendor.vendorId,
          vendorName: vendor.vendorName,
          vendorContactId: vendor.vendorContactId,
          contactFullName: vendor.personByVendorContactId?.fullName || "",
          vendorUrl: vendor.vendorUrl,
          vendorComments: vendor.vendorComments,
          vendorActive: vendor.vendorActive,
          phoneNumber: vendor.phoneNumber,
        }))
      } catch (error) {
        console.error("Error fetching vendor data:", error)
      }
    },
    async filterChange(data) {
      if (this.debug) console.log("[VendorSearch.vue] filterChange:", data)
      if (data.id === "vendorTextSearch") {
        this.searchText = data.val
      }
    },
    async filterOnClick(data) {
      if (this.debug) console.log("[vendorSearch.vue] filterOnClick:", data)
      if (data.id === "vendorTextSearch" && data.val === "submit") {
        // Filter vendors  when enter is pressed
        const searchText = this.searchText?.toLowerCase() || ""
        const vendors = await this.$store.dispatch("asset/getVendors")
        this.vendors = vendors
          .filter(vendor => vendor.vendorName?.toLowerCase().includes(searchText))
          // vendor.vendorId?.toLowerCase().includes(searchText) ||
          //vendor.vendorContactId?.includes(searchText))
          .map(vendor => ({
            vendorId: vendor.vendorId,
            vendorName: vendor.vendorName,
            vendorContactId: vendor.vendorContactId,
            contactFullName: vendor.personByVendorContactId?.fullName || "",
            vendorUrl: vendor.vendorUrl,
            vendorComments: vendor.vendorComments,
            vendorActive: vendor.vendorActive,
          }))
      } else if (data.id === "vendorTextSearch" && data.val === "reset") {
        // Reset the search when reset is clicked
        this.searchText = ""
        await this.initialSearch()
      }
    },
    buttonOnClick(id) {
      console.log(id)
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    onRowClick(data) {
      if (data && data.vendorId) {
        this.$router.push({
          path: `/vendor/details/${data.vendorId}`,
        })
      }
    },
  },
}
</script>

<template>
  <div id="VendorSearch">
    <SearchTopBar
      :filters="filters"
      @filterChange="filterChange"
      @filterClick="filterOnClick"
      @buttonClick="buttonOnClick"
    />
    <div class="PageContent">
      <AdminSearchResults
        :fields="results.fields"
        :records="results.records"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :collapseRows="collapseRows"
        :cellCap="cellCap"
        @onRowClick="onRowClick"
      />
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>
