import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/vendor/Search.vue"
import Details from "../views/vendor/Details.vue"
import Create from "../views/vendor/Create.vue"

Vue.use(VueRouter)

const prefix = "/vendor/"

const routes = [
  {
    path: prefix,
    name: "Vendor",
    component: Search,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Vendor_Create",
    component: Create,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Vendor_Search",
    component: Search,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Vendor_Details",
    component: Details,
  },
]

export default routes
