import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/manufacturer/Search.vue"
import Details from "../views/manufacturer/Details.vue"
import Create from "../views/manufacturer/Create.vue"

Vue.use(VueRouter)

const prefix = "/manufacturer/"

const routes = [
  {
    path: prefix,
    name: "Manufacturer",
    component: Search,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Manufacturer_Create",
    component: Create,
  },

  {
    path: `${prefix}search/:params*`,
    name: "Manufacturer_Search",
    component: Search,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Manufacturer_Details",
    component: Details,
  },
]

export default routes
