<script>
import { mapState } from "vuex"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import Alert from "@/components/global/Alert"
import DetailsContainer from "@/components/global/DetailsContainer"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
  
import validation from "@/mixins/validation"
  
export default {
  name: "Create",
  components: {
    DetailsTopBar,
    DetailsContainer,
    IncidentModal,
    Alert,
  },
  mixins: [ validation ],
  data() {
    return {
      debug: false,
      canCreateProduct: false,
      displayName: "Create New Product Record",
      alertData: {
        message: "<div class='center'>You currently don't have any permissions to create a Product. If access is required, please submit an incident above to App Support to request access</div>",
        variant: "warning",
        html: true,
      },
      coreRows: [
        {
          items: [
            {
              data: {
                id: "manufacturerId",
                label: "Product Manufacturer",
                readOnly: false,
                value: "",
                state: null,
                message: "Product Manufacturer is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "manufacturerpicker",
              cols: 6,
            },
            {
              data: {
                id: "productModel",
                label: "Product Model",
                readOnly: false,
                value: "",
                state: null,
                message: "Product Model is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "productName",
                label: "Product Name",
                readOnly: false,
                value: "",
                state: null,
                message: "Product Name is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "userManual",
                label: "Product Manual Url",
                readOnly: false,
                value: "",
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "assetTypes",
                label: "Asset Type(s)",
                readOnly: false,
                multiple: true,
                value: [],
                state: null,
                message: "Asset Type is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "assettypepicker",
              cols: 12,
            },
            {
              data: {
                id: "hazardCategory",
                label: "Hazard Category",
                readOnly: false,
                value: "",
              },
              type: "hazardpicker",
              cols: 6,
            },
            {
              data: {
                id: "controlCode",
                label: "Export Control Code",
                readOnly: false,
                value: "",
              },
              type: "controlpicker",
              cols: 6,
            },
            {
              cols: 6,
              type: "assetlabpicker",
              data: {
                id: "controlAssignedBy",
                label: "Control Assigned By",
                value: "",
              },
            },
            {
              cols: 6,
              type: "datepicker",
              data: {
                id: "controlAssignedDate",
                label: "Date Control Assigned",
                value: "",
              },
            },
            {
              data: {
                id: "controlNotes",
                label: "Export Control Notes",
                readOnly: false,
                value: "",
              },
              type: "textarea",
              cols: 12,
            },
            {
              data: {
                id: "productActive",
                label: "Product Active",
                readOnly: !this.canCreateProduct,
                value: true,
                switch: true,
                text: "Active",
                state: null,
                message: "Product active is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "checkbox",
              cols: 12,
            },
            {
              data: {
                id: "productDesc",
                label: "Product Description",
                value: "",
              },
              type: "textarea",
              cols: 12,
            },
            {
              data: {
                id: "instrumentClasses",
                label: "Instrument Classes",
                readOnly: false,
                multiple: true,
                value: [],
                options: [], //fill from store
                state: null,
                message: "Instrument Classes are required",
              },
              // validation: { rules: { required: true } },
              type: "selectpicker",
              cols: 12,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    incidentGroup() {
      return Config.asset.incidentGroup 
    },
    topBarItems() {
      const items = []
      // show "Create" button only if user can create
      if (this.canCreateProduct) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Product",
          position: "right",
          variant: "light",
        })
      }
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })
      return items
    },
  },
  created() {
    // change this later to whatever permission is applied 
    this.canCreateProduct = this.roles.includes("asset.asset")
    //this.canCreateProduct = true
    // get all instrument classes
    this.$store.dispatch("asset/getAllInstrumentClassDetails").then(data => {
      const instrumentClassOptions = data.map(item => ({
        value: item.instrumentClassCode,
        text: `${item.instrumentClassCode} - ${item.instrumentClassName}`,
        label: `${item.instrumentClassCode} - ${item.instrumentClassName}`,
      }))
      this.coreRows[0].items.forEach(item => {
        if (item.data.id === "instrumentClasses") {
          item.data.options = instrumentClassOptions
        }
      })
    })
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        this.createProduct()
      } else if (buttonType === "createIncidentButton") {
        if (!this.email) {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
  
    createProduct() {
      const formValues = {}
      this.coreRows.forEach(row => {
        row.items.forEach(item => {
          formValues[item.data.id] = item.data.value
        })
      })
      const {
        manufacturerId,
        productName,
        productActive,
        productModel,
        productDesc,
        userManual,
        hazardCategory,
        controlCode,
        controlNotes,
        controlAssignedBy,
        controlAssignedDate,
        assetTypes,
        instrumentClasses,
      } = formValues
  
      const finalAssetTypes = Array.isArray(assetTypes) ? assetTypes.map(a => (typeof a === "object" && a.value) ? a.value : a) : []
      const finalInstrClasses = Array.isArray(instrumentClasses) ? instrumentClasses.map(ic => (typeof ic === "object" && ic.value) ? ic.value : ic): []
      // check if product exists already
      this.$store.dispatch("asset/getMatchedProducts", {
        productName,
        productModel,
      }).then(exists => {
        if (exists) {
          this.$bvToast.toast("This Product already exists", {
            title: "Add New Product",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }
        this.$store.dispatch("asset/createProductDetail", {
          manufacturerId,
          productName,
          productActive,
          productModel,
          productDesc,
          userManual,
        }).then(productId => {
          const promises = []
          finalAssetTypes.forEach(assetTypeId => {
            promises.push(this.$store.dispatch("asset/createProductAssetType", { productId, assetTypeId }))
          })
          if (finalInstrClasses.length) {
            finalInstrClasses.forEach(ic => {
              promises.push(this.$store.dispatch("asset/createProductToInstrClass", {
                productId,
                instrumentClassCode: ic,
              }))
            })
          }
          promises.push(this.$store.dispatch("asset/createProductControlDetail", {
            productId,
            hazardCode: hazardCategory,
            itarEccnCode: controlCode,
            notes: controlNotes,
            assignedBy: controlAssignedBy,
            assignedDate: controlAssignedDate,
          }))
  
          return Promise.all(promises).then(() => productId)
        }).then(productId => {
          this.$bvToast.toast("Product created successfully.", {
            title: "Add New Product",
            autoHideDelay: 5000,
            variant: "success",
          })
          //redirect to the newly created product detail page:
          setTimeout(() => {
            this.$router.push(`/product/details/${productId}`)
          }, 500)
        })
      }).catch(err => {
        console.error("Error creating product:", err)
        this.$bvToast.toast(`Product creation failed: ${err.message}`, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        })
      })
    },
    handleValidationResults(errors) {
      if (!errors || !Array.isArray(errors)) return
      const productNameDup = errors.some(e => e.field === "productName" && e.rule === "unique")
      if (productNameDup) {
      }
    },
  },
}
</script>

<template>
  <div id="ProductCreate">
    <DetailsTopBar
      :items="topBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    
    <div class="PageContent">
      <Alert v-if="!canCreateProduct" :value="alertData" />
      <template v-else>
        <DetailsContainer
          title="Create New Product"
          :rows="coreRows"
          @validationErrors="handleValidationResults"
        />
      </template>
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>
  
<style scoped>
.alert span div.center {
text-align: center;
}
</style>
  
