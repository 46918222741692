<script>
import {mapState} from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import AdminSearchResults from "@/components/global/AdminSearchResults.vue"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    AdminSearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Product Search",
      collapseRows: true,
      cellCap: 2,
      products: [],
      filters: [
        {
          id: "productTextSearch",
          type: "text",
          value: "",
          cols: 6,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },
      ],
      sortBy: "productId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("auth", ["role", "email"]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
    results() {
      return {
        fields: [
          {key: "productId", label: "ID", sortable: true},
          {key: "manufacturerName", label: "Manufacturer", sortable: true},
          {key: "productName", label: "Product Name", sortable: true},
          {key: "productModel", label: "Model", sortable: true},
          {key: "productActive", label: "Active", sortable: true},
          {key: "userManual", label: "Manual", sortable: true},
          {key: "productDesc", label: "Description", sortable: true},
          {key: "controlCode", label: "Export Control Code", sortable: true},
          {key: "controlAssignedBy", label: "Control Assigned By", sortable: true},
          {key: "controlAssignedDate", label: "Control Assigned Date", sortable: true},

        ],
        records: this.products,
      }
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    async initialSearch() {
      try {
        const products = await this.$store.dispatch("asset/getProductDetails")
        this.products = products.map(product => ({
          productId: product.productId,
          productName: product.productName,
          productModel: product.productModel,
          manufacturerName: product.assetManufacturerByManufacturerId?.manufacturerName,
          productActive: product.productActive,
          userManual: product.userManual,
          productDesc: product.productDesc,
          controlCode:  product.productControlDetailByProductId?.itarEccnCode,
          controlAssignedBy: product.productControlDetailByProductId?.assignedBy,
          controlAssignedDate: product.productControlDetailByProductId?.assignedDate,

        }))
      } catch (error) {
        console.error("Error fetching products:", error)
      }
    },
    async filterChange(data) {
      if (this.debug) console.log("[ProductSearch.vue] filterChange:", data)
      if (data.id === "productTextSearch") {
        // Store the search text but don't filter yet - wait for enter/reset
        this.searchText = data.val
      }
    },
    buttonOnClick(id) {
      console.log(id)
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    async filterOnClick(data) {
      console.log(data)
      if (this.debug) console.log("[ProductSearch.vue] filterOnClick:", data)
      if (data.id === "productTextSearch" && data.val === "submit") {
        // Filter products when enter is pressed
        const searchText = this.searchText?.toLowerCase() || ""
        const products = await this.$store.dispatch("asset/getProductDetails")
        this.products = products
          .filter(product => product.productName?.toLowerCase().includes(searchText) ||
            product.productModel?.toLowerCase().includes(searchText) ||
            product.assetManufacturerByManufacturerId?.manufacturerName?.toLowerCase().includes(searchText))
          .map(product => ({
            productId: product.productId,
            productName: product.productName,
            productModel: product.productModel,
            manufacturerName: product.assetManufacturerByManufacturerId?.manufacturerName,
            productActive: product.productActive,
            userManual: product.userManual,
            productDesc: product.productDesc,
            controlCode:  product.productControlDetailByProductId?.itarEccnCode,
            controlAssignedBy: product.productControlDetailByProductId?.assignedBy,
            controlAssignedDate: product.productControlDetailByProductId?.assignedDate,
          }))
      } else if (data.id === "productTextSearch" && data.val === "reset") {
        // Reset the search when reset is clicked
        this.searchText = ""
        await this.initialSearch()
      }
    },
    onRowClick(data) {
      if (data && data.productId) {
        this.$router.push({
          path: `/product/details/${data.productId}`,
        })
      }
    },
  },
}
</script>

<template>
  <div id="ProductSearch">
    <SearchTopBar
      :filters="filters"
      @filterChange="filterChange"
      @filterClick="filterOnClick"
      @buttonClick="buttonOnClick"
    />
    <div class="PageContent">
      <AdminSearchResults
        :fields="results.fields"
        :records="results.records"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :collapseRows="collapseRows"
        :cellCap="cellCap"
        @onRowClick="onRowClick"
      />
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>
