<script>
import axios from "axios"
import { mapState, mapGetters } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [ validation ],
  data() {
    return {
      debug: false,
      canEditProduct: false,
      incDisplayName: "",
      productId: 0,
      buttonVariant: "light",
      originalRows: [],
      coreRows: [],
      loaded: false,
      error: "",
      detailsExpanded: true,
      initialized: false,
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    ...mapGetters("auth", ["canEditItarRecord"]),
    detailsTopBarItems() {
      const items = []

      if (this.canEditProduct) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      items.push({
        id: "backToSearchButton",
        type: "button",
        label: "↩ Back to Product Search",
        position: "left",
        variant: this.buttonVariant,
      })

      return items
    },
    incidentGroup() {
      return Config.asset.incidentGroup
    },
  },
  watch: {
    "$route.params.params": {
      immediate: true,
      handler(newId) {
        if (!newId) {
          this.error = "Missing Product ID Parameter"
        } else if (isNaN(newId)) {
          this.error = "Invalid Product ID Format"
        } else {
          const productId = parseInt(newId)
          if (this.productId !== productId) {
            this.productId = productId
            this.updateView()
          }
        }
      },
    },
  },
  created() {
    if (this.debug) console.log("[ProductDetails.vue] created")
    this.canEditProduct = this.roles.includes("asset.asset")
    //this.canEditProduct = true
  },
  methods: {
    async updateView() {
      try {
        if (!this.$route.params.params) {
          this.error = "Missing Product ID Parameter"
          return
        }
        
        if (isNaN(this.$route.params.params)) {
          this.error = "Invalid Product ID Format"
          return
        }

        this.productId = parseInt(this.$route.params.params)
        await this.loadCurrentRecord()
      } catch (err) {
        console.error("[ProductDetails.vue] Error in updateView:", err)
        this.error = `Failed to update view: ${err.message}`
      }
    },
    
    loadCurrentRecord() {
      if (this.debug) console.log("[ProductDetails.vue] loadCurrentRecord for productId:", this.productId)

      this.loaded = false
      this.error = ""

      return this.$store.dispatch("asset/getProductById", this.productId)
        .then(data => {
          if (!data) {
            throw new Error("No data returned from API")
          }

          this.incDisplayName = `${data.productId} - ${data.productName || ""}`
          this.buildCoreRows(data)
          this.loaded = true
        })
        .catch(error => {
          console.error("[ProductDetails.vue] Error loading product:", error)
          this.error = `Failed to load product details: ${error.message}`
          this.loaded = false
          throw error
        })
    },
    buildCoreRows(data) {
      const selectedAssetTypeIds = data?.productAssetTypesByProductId?.nodes?.map(node => Number(node.assetTypeByAssetTypeId.assetTypeId)) || []
      console.log("Selected asset type IDs:", selectedAssetTypeIds)
      const selectedInstrumentCodes = data?.productToInstrClassesByProductId?.nodes?.map(node => node.instrumentClassCode) || []
      const coreRows = [
        {
          items: [
            {
              data: {
                id: "manufacturerId",
                label: "Product Manufacturer",
                value: data?.assetManufacturerByManufacturerId?.manufacturerId ?? "",
                defaultLabel: data?.assetManufacturerByManufacturerId?.manufacturerName ?? "",
                readOnly: !this.canEditProduct,
                message: "Product Manufacturer is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "manufacturerpicker",
              cols: 6,
            },
            {
              data: {
                id: "productModel",
                label: "Product Model",
                value: data.productModel || "",
                readOnly: !this.canEditProduct,
                message: "Product Model is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "productName",
                label: "Product Name",
                value: data.productName || "",
                readOnly: !this.canEditProduct,
                message: "Product Name is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "userManual",
                label: "Product Manual URL",
                value: data.userManual || "",
                readOnly: !this.canEditProduct,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "assetTypes",
                label: "Asset Type(s)",
                readOnly: !this.canEditProduct,
                multiple: true,
                value: selectedAssetTypeIds,
              },
              type: "assettypepicker",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "hazardCategory",
                label: "Hazard Category",
                readOnly: !this.canEditItarRecord,
                // value: data.productControlDetailByProductId.hazardCode,
                value: data?.productControlDetailByProductId?.hazardCode ?? "",
                defaultLabel: "Select One...",
              },
              type: "hazardpicker",
              cols: 6,
            },
            {
              data: {
                id: "controlCode",
                label: "Export Control Code",
                readOnly: !this.canEditItarRecord,
                // value: data.productControlDetailByProductId.itarEccnCode,
                value: data?.productControlDetailByProductId?.itarEccnCode ?? "",
                defaultLabel: "Select One...",
              },
              type: "controlpicker",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              cols: 6,
              type: "assetlabpicker",
              data: {
                id: "controlAssignedBy",
                label: "Control Assigned By",
                readOnly: !this.canEditItarRecord,
                // value: data.productControlDetailByProductId.assignedBy,
                value: data?.productControlDetailByProductId?.assignedBy ?? "",
                defaultLabel: "Select One...",
              },
            },
            {
              cols: 6,
              type: "datepicker",
              data: {
                id: "controlAssignedDate",
                label: "Date Control Assigned",
                readOnly: !this.canEditItarRecord,
                // value: data.productControlDetailByProductId.assignedDate,
                value: data?.productControlDetailByProductId?.assignedDate ?? "",
              },
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "controlNotes",
                label: "Export Control Notes",
                readOnly: !this.canEditItarRecord,
                // value: data.productControlDetailByProductId.notes,
                value: data?.productControlDetailByProductId?.notes ?? "",
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "productActive",
                label: "Product Active",
                value: data.productActive ?? false,
                readOnly: !this.canEditProduct,
                switch: true,
                text: "Active",
              },
              type: "checkbox",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "productDesc",
                label: "Product Description",
                value: data.productDesc || "",
                readOnly: !this.canEditProduct,
                rows: 4,
                state: null,
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "instrumentClasses",
                label: "Instrument Classes",
                readOnly: !this.canEditProduct,
                multiple: true,
                value: selectedInstrumentCodes,
                options: [],
                state: null,
              },
              type: "selectpicker",
              cols: 12,
            },
          ],
        },
      ]
      this.$store.dispatch("asset/getAllInstrumentClassDetails").then(instrClasses => {
        const instrClassField = this.coreRows.find(row => row.items.some(item => item.data.id === "instrumentClasses"))?.items[0]
    
        if (instrClassField) {
          const classNameMap = instrClasses.reduce((acc, code) => {
            acc[code.instrumentClassCode] = code.instrumentClassName
            return acc
          }, {})
          instrClassField.data.options = instrClasses.map(code => ({
            value: code.instrumentClassCode,
            text: `${code.instrumentClassCode} - ${code.instrumentClassName}`,
            label: `${code.instrumentClassCode} - ${code.instrumentClassName}`,
          }))
          instrClassField.data.value = selectedInstrumentCodes.map(code => ({
            value: code,
            text: `${code} - ${classNameMap[code] || ""}`,
            label: `${code} - ${classNameMap[code] || ""}`,
          }))
        }
      })

      this.originalRows = JSON.parse(JSON.stringify(coreRows))
      this.coreRows = coreRows
      console.log("Core rows after build:", this.coreRows) 
      this.loaded = true
    },
    onTopBarButtonClick(id) {
      if (id === "saveRecordButton") {
        if (!this.canEditProduct) {
          this.$bvToast.toast(`You do not have permission to save changes.`, {
            title: "Product Edit",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }

        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }

        const productUpdatePayload = {
          productId: this.productId,
        }

        const productControlDetailUpdatePayload = {
          productId: this.productId,
        }

        let selectedAssetTypeIds = []
        let selectedInstrumentCodes = []
        // get values from coreRows
        this.coreRows.forEach(row => {
          row.items.forEach(item => {
            if (item.data.id === "assetTypes") {
              // get array of asset type IDs
              const assetTypes = Array.isArray(item.data.value) ? item.data.value : []
              console.log("Raw asset types from picker:", assetTypes)
          
              selectedAssetTypeIds = assetTypes.map(type => {
                if (typeof type === "object" && type.value) return Number(type.value)
                return Number(type)
              }).filter(id => !isNaN(id) && id > 0)
          
              console.log("Processed asset type IDs:", selectedAssetTypeIds)
            } else if (item.data.id === "instrumentClasses") {
              // get array of instrument class codes
              const instrClasses = Array.isArray(item.data.value) ? item.data.value : []
              selectedInstrumentCodes = instrClasses.map(ic => {
                if (typeof ic === "object" && ic.value) return ic.value
                return ic
              })
              console.log("Selected instrument codes:", selectedInstrumentCodes)
            } else if (item.data.id === "hazardCategory" || item.data.id === "controlCode" || item.data.id === "controlAssignedDate" || item.data.id === "controlNotes" || item.data.id === "controlAssignedBy") {
              if (item.data.id === "hazardCategory") {
                productControlDetailUpdatePayload.hazardCode = item.data.value
              } else if (item.data.id === "controlCode") {
                productControlDetailUpdatePayload.itarEccnCode = item.data.value
              } else if (item.data.id === "controlAssignedDate") {
                productControlDetailUpdatePayload.assignedDate = item.data.value
              } else if (item.data.id === "controlNotes") {
                productControlDetailUpdatePayload.notes = item.data.value
              } else if (item.data.id === "controlAssignedBy") {
                productControlDetailUpdatePayload.assignedBy = item.data.value
              }
            } else {
              productUpdatePayload[item.data.id] = item.data.value
            }
          })
        })
        this.isLoading = true
        this.$store.dispatch("asset/updateProductDetailByProductId", productUpdatePayload)
          .then(() => {
            console.log("Product details updated, now updating asset types:", selectedAssetTypeIds)
            const updatePromises = []
            // Add asset type update promise if needed
            updatePromises.push(this.$store.dispatch("asset/updateProductAssetTypes", {
              productId: this.productId,
              assetTypeIds: selectedAssetTypeIds,
            }))
            // Add instrument class update promise if needed
            updatePromises.push(this.$store.dispatch("asset/updateProductInstrumentClasses", {
              productId: this.productId,
              instrumentClassCodes: selectedInstrumentCodes,
            }))
            //Update Product Control Detail only if there is an actual export control code associated with the product
            console.log(productControlDetailUpdatePayload.itarEccnCode)
            updatePromises.push(this.$store.dispatch("asset/upsertProductControlDetail", productControlDetailUpdatePayload))
            // Return Promise.all if we have updates, otherwise return resolved promise
            return updatePromises.length > 0 ? Promise.all(updatePromises) : Promise.resolve()
          })
          .then(() => {
            this.$bvToast.toast(`Updates completed successfully.`, {
              title: "Product Edit",
              autoHideDelay: 5000,
              variant: "success",
            })
            //reload the record to get fresh data
            return this.loadCurrentRecord()
          })
          .then(() => {
            this.isLoading = false
            this.originalRows = JSON.parse(JSON.stringify(this.coreRows))
          })
          .catch((error) => {
            console.error("[ProductDetails.vue] Error saving:", error)
            this.isLoading = false
            this.$bvToast.toast(`Failed to save changes: ${error.message}`, {
              title: "Product Edit",
              autoHideDelay: 5000,
              variant: "danger",
            })
          })
      } else if (id === "backToSearchButton") {
        this.$router.push(`/product/search/`)
      } else if (id === "createIncidentButton") {
        if (this.email === "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    }
    ,
  },
}
</script>

<template>
  <div id="ProductDetails">
    <DetailsTopBar
      :items="detailsTopBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <div v-if="error.length > 0" class="errorPrompt">
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="Product Details"
            :rows="coreRows" 
            :expanded="detailsExpanded"
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
        </template>
        <div v-else class="errorPrompt">
          Unable to Retrieve Product Record
        </div>
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      subject="Product Update Request"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
</style>
