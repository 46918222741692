<script>
import { mapState } from "vuex"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import DetailsContainer from "@/components/global/DetailsContainer"
import IncidentModal from "@/components/global/IncidentModal"
import Alert from "@/components/global/Alert"
import Config from "@/config"
import validation from "@/mixins/validation"
  
export default {
  name: "Create",
  components: {
    DetailsTopBar,
    DetailsContainer,
    IncidentModal,
    Alert,
  },
  mixins: [ validation ],
  data() {
    return {
      debug: false,
      canCreateAssetType: false,
      displayName: "Create Asset Type",
      alertData: {
        message: "<div class='center'>You currently don't have any permissions to create an Asset Type. If access is required, please submit an incident above to App Support to request access</div>",
        variant: "warning",
        html: true,
      },
      coreRows: [
        {
          items: [
            {
              data: {
                id: "assetTypeName",
                label: "Asset Type Name",
                value: "",
                readOnly: false,
                state: null,
                message: "Asset Type Name is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "assetTypeDesc",
                label: "Asset Type Description",
                value: "",
                readOnly: false,
                state: null,
                message: "Description is required",
              },
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "textarea",
              cols: 6,
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    topBarItems() {
      const items = []
      // show create button only if user has permission
      if (this.canCreateAssetType) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Asset Type",
          position: "right",
          variant: "light",
        })
      }
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })
      return items
    },
  },
  created() {
    this.canCreateAssetType = this.roles.includes("asset.asset")
    //this.canCreateAssetType = true
    if (!this.email) {
      this.$store.dispatch("auth/retrieveEmail")
    }
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        this.createAssetType()
      } else if (buttonType === "createIncidentButton") {
        if (!this.email) {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    createAssetType() {
      const fields = {}
      this.coreRows.forEach(row => {
        row.items.forEach(item => {
          fields[item.data.id] = item.data.value
        })
      })

      const { assetTypeName, assetTypeDesc } = fields
      this.$store.dispatch("asset/getMatchedAssetTypes", {
        assetTypeName,
      })
        .then(exists => {
          if (exists) {
            this.$bvToast.toast("This Asset Type already exists.", {
              title: "Create Asset Type",
              autoHideDelay: 5000,
              variant: "danger",
            })
            return
          }
          this.$store.dispatch("asset/createAssetType", {
            assetTypeName,
            assetTypeDesc,
          })
            .then(newTypeObj => {
              this.$bvToast.toast("Asset Type created successfully.", {
                title: "Create Asset Type",
                autoHideDelay: 5000,
                variant: "success",
              })
              if (newTypeObj && newTypeObj.assetTypeId) {
                // because we navigate away to the details page when an asset type is created, so delay navigation to see toast message
                setTimeout(() => {
                  this.$router.push(`/assettypes/details/${newTypeObj.assetTypeId}`)
                }, 500)
              }
            })
            .catch(err => {
              console.error("Error creating asset type:", err)
              this.$bvToast.toast(`Failed to create asset type: ${err.message}`, {
                title: "Create Asset Type",
                autoHideDelay: 5000,
                variant: "danger",
              })
            })
        })
        .catch(err => {
          console.error("Error checking duplicates:", err)
        })
    },
  
    handleValidationResults(errors) {
      if (errors && Array.isArray(errors)) {
      }
    },
  },
}
</script>

<template>
  <div id="CreateAssetType">
    <DetailsTopBar
      :items="topBarItems"
      @buttonClick="onTopBarButtonClick"
    />
  
    <div class="PageContent">
      <Alert v-if="!canCreateAssetType" :value="alertData" />
      <template v-else>
        <DetailsContainer
          title="Create New Asset Type"
          :rows="coreRows"
          @validationErrors="handleValidationResults"
        />
      </template>
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>
  
  <style scoped>
  .alert span div.center {
    text-align: center;
  }
  </style>
  
