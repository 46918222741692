<script>
import { mapState, mapActions, mapGetters } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import email from "@/mixins/email"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    email,
    validation,
  ],
  data() {
    return {
      debug: false,
      incDisplayName: "",
      itarEccnCode: 0,
      buttonVariant: "light",
      originalRows: [],
      coreRows: [],
      loaded: false,
      error: "",
      detailsExpanded: true,
      
    }
  },
  computed: {
    ...mapState("auth", [
      "roles",
      "email",
    ]),
    ...mapGetters("auth", ["canEditItarRecord"]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "backToSearchButton",
        type: "button",
        label: "↩ Back to Itar Search",
        position: "left",
        variant: this.buttonVariant,
      })

      if (this.canEditItarRecord) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
        items.push({
          id: "createIncidentButton",
          type: "button",
          label: "Create Incident",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      return items
    },
    coreRowsString() {
      return JSON.stringify(this.coreRows)
    },
  },
  watch: {
    $route() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Export Control Code Parameter"
      } else {
        if (this.itarEccnCode != this.$route.params.params) {
          this.updateView() 
        }
      }
    },
    coreRowsString: {
      handler(newValStr, oldValStr) {
        const newVal = JSON.parse(newValStr)
        const oldVal = JSON.parse(oldValStr)

      },
      deep: true,
    },
  },
  created() {
    this.updateView()
  },
  methods: {
    async updateView() {
      if (
        (typeof this.$route.params === "undefined",
        typeof this.$route.params.params === "undefined")
      ) {
        this.error = "Missing Export Control Code Parameter"
      } else {
        this.itarEccnCode = this.$route.params.params
        return await this.loadCurrentRecord()
      }
    },
    async checkActiveProducts(payload) {
      if (payload.active === false) {
        try {
          const data = await this.$store.dispatch("itar/retrieveProductDetailsByItarEccnCode", payload.itarEccnCode)
          const activeProductsList = data.filter(item => item.productDetailByProductId.productActive).map(item => item.productDetailByProductId)
          
          if (activeProductsList.length > 0) {
            const formattedList = activeProductsList.map(product => `${product.productName}: ${product.productModel}`).join("\n")
            
            this.$bvToast.toast(formattedList, {
              title: `You are trying to inactivate this itar/eccn: ${payload.itarEccnCode} but the below products use it and are still active. Please first go and set the following products to inactive and then you can return here and inactivate this ITAR/ECCN number.`,
              autoHideDelay: 20000,
              variant: "danger",
            })
            
            return false
          }
        } catch (error) {
          console.error("Error checking active products:", error)
          return false
        }
      }
      return true
    },
    loadCurrentRecord() {
      return this.$store
        .dispatch("itar/retrieveDetailsRecordById", this.itarEccnCode)
        .then(data => {
          if (this.debug) console.log("[Details.vue] loadCurrentRecord:", data)
          this.itarEccnCode = data.itarEccnCode
          this.incDisplayName = `${data.itarEccnCode} - ${data.itarEccnDesc === null ? "" : `${data.itarEccnDesc}`}`

          const coreRows = [
            {
              items: [
                {
                  data: {
                    id: "itarEccnCode",
                    label: "Export Control Code",
                    value: data.itarEccnCode,
                    readOnly: true,
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: true,
                      maxLength: 50,
                    },
                  },
                  type: "text",
                  cols: 4,
                },
                {
                  data: {
                    id: "sensitivityRating",
                    label: "Sensitivity Rating",
                    value: data.sensitivityRating,
                    readOnly: !this.canEditItarRecord,
                    state: null,
                  },
                  type: "sensitivityratingpicker",
                  cols: 4,
                },
                {
                  data: {
                    id: "active",
                    label: "Active",
                    value: data.active,
                    readOnly: !this.canEditItarRecord,
                    switch: true,
                  },
                  type: "checkbox",
                  cols: 4,
                },
              ],
            },
            {
              items: [
                {
                  data: {
                    id: "itarEccnDesc",
                    label: "Export Control Desc",
                    value: data.itarEccnDesc,
                    readOnly: !this.canEditItarRecord,
                    state: null,
                  },
                  validation: {
                    rules: {
                      required: false,
                      maxLength: 2147483647,
                    },
                  },
                  type: "textarea",
                  cols: 12,
                },
              ],
            },
          ]

          this.originalRows = JSON.parse(JSON.stringify(coreRows))
          this.coreRows = coreRows
          this.loaded = true
          return
        })
    },
    async onTopBarButtonClick(id) {
      if (id == "saveRecordButton") {
        const activeProductsList = []
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        const payload = {
          itarEccnCode: this.itarEccnCode,
        }
        this.coreRows.forEach(row => {
          row.items.map(item => {
            payload[item.data.id] = item.data.value
          })
        })

        // Check for active products before updating
        const shouldProceed = await this.checkActiveProducts(payload)
        if (!shouldProceed) {
          return // Exit if we shouldn't proceed
        }
        
        this.$store.dispatch("itar/updateItarEccnDetailByItarEccnCode", payload).then(response => {
          if (response.status === 200) {
            const responseData = response.data.data.updateItarEccnDetailByItarEccnCode
            this.sendNotificationEmail()
            
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Itar Eccn Edit",
              autoHideDelay: 5000,
              variant: "success",
            })
          } else {
            this.$bvToast.toast(`Failed to save modifications.`, {
              title: "Itar Eccn Edit",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
          this.originalRows = JSON.parse(JSON.stringify(this.coreRows))
        })
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } else if (id === "backToSearchButton") {
        this.$router.push(`/itar/search/`)
      }
    },
    sendNotificationEmail() {
      const rowIndex = this.coreRows?.findIndex(row => {
        return row?.items?.some(field => {
          return field?.data?.id === "itarEccnCode"
        })
      })
      const fieldIndex = this.coreRows[rowIndex]?.items?.findIndex(field => field?.data?.id === "itarEccnCode")
      const itarEccnCode = this.coreRows[rowIndex]?.items?.[fieldIndex]?.data?.value

      const payload = {
        url: `${Config.notificationService.host}/sendToMailingList`,
        mailingLists: [Config.itar.mailingList],
        senderEmail: Config.notificationService.senderEmail,
        title: `ITAR/ECCN Admin`,
        subject: `ITAR/ECCN "${itarEccnCode}" Updated`,
        newData: this.coreRows,
        oldData: this.originalRows,
        editUrl: `${Config.app.host}/#/itar/details/${this.itarEccnCode}`,
      }

      this.notifyMailingList(payload)
    },
  },
}
</script>

<template>
  <div id="ItarDetails">
    <DetailsTopBar
      :items="detailsTopBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <div
        v-if="error.length > 0"
        class="errorPrompt"
      >
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="ITAR/ECCN Details"
            :rows="coreRows" 
            :expanded="detailsExpanded"
            @validationErrors="handleValidationResults"
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
        </template>
        <div
          v-else
          class="errorPrompt"
        >
          Unable to Retrieve ITAR/ECCN Record
        </div>
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      subject="ITAR/ECCN Update Request"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.sectionHeaderButtons button {
  position: absolute;
  top: 5px;
}
</style>
