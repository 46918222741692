<script>
import { mapState } from "vuex"
import PersonFacilityPicker from "@/components/global/PersonFacilityPicker"
import SitePickerStatic from "@/components/global/SitePickerStatic"
import InstrumentPickerStatic from "@/components/global/InstrumentPickerStatic"
import InstrumentList from "@/components/instrument/InstrumentList.vue"
import ColorCodeLegend from "@/components/instrument/ColorCodeLegend.vue"
import Toggles from "@/components/instrument/Toggles.vue"

export default {
  components: {
    SitePickerStatic,
    PersonFacilityPicker,
    InstrumentPickerStatic,
    InstrumentList,
    ColorCodeLegend,
    Toggles,
  },
  data() {
    return {
      componentKey: 0,
      masterStartDate: null,
      masterEndDate: null,
      displayStartDate: null,
      displayEndDate: null,
      submittedStartDate: null,
      submittedEndDate: null,
      site: false,
      armsites: {
        id: "armsites",
        label: "Sites",
        defaultLabel: "All Sites",
        placeholder: "All Sites",
        multiple: true,
        value: [],
        options: [],
      },
      facilities: {
        id: "currentFacility",
        defaultLabel: "All Facilities",
        placeholder: "All Facilities",
        siteCode: "",
        readOnly: false,
        value: { value: "" },
        options: [],
      },
      instruments: {
        id: "instruments",
        label: "Instruments",
        placeholder: "All Instruments",
        multiple: false,
        value: { value: "" },
        options: [],
        retrieveAllOnCreate: false,
      },
      selectedSite: null,
      selectedFacility: null,
      quickFiltered: [],
    }
  },
  computed: {
    ...mapState("instrument", ["fullPersonInstruments"]),
    showSites() {
      return this.armsites.options.length > 0
    },
    showFacilities() {
      return this.facilities.options.length > 0
    },
    filteredPersonInstruments() {
      let filtered = this.fullPersonInstruments
      if (!filtered || !filtered.length) {
        return []
      }

      if (this.quickFiltered.length !== 0) {
        filtered = filtered.filter(itm => !this.quickFiltered.includes(itm.instrumentClassCode))
      }

      const facilityValue =
        this.facilities.value && this.facilities.value.value !== ""
          ? this.facilities.value.value
          : null

      if (facilityValue) {
        filtered = filtered.filter(itm => itm.facilityCode === facilityValue)
      }

      const instrumentValue =
        this.instruments.value && this.instruments.value.value !== ""
          ? this.instruments.value.value
          : null

      if (instrumentValue) {
        filtered = filtered.filter(itm => itm.instrumentClassCode === instrumentValue)
      }
      return filtered
    },
  },
  created() {
    this.getPersonInstruments()
    this.resetMasterDates()
  },
  methods: {
    resetMasterDates() {
      this.masterStartDate = null
      this.masterEndDate = null
      this.$store.dispatch("instrument/setMasterStartDate", null)
      this.$store.dispatch("instrument/setMasterEndDate", null)
    },
    updateStartDate(date) {
      this.$store.dispatch("setMasterStartDate", date)
    },
    updateEndDate(date) {
      this.$store.dispatch("setMasterEndDate", date)
    },
    handleSubmitDates(event) {
      event.preventDefault()
      const formattedStartDate = this.formatDate(this.masterStartDate)
      const formattedEndDate = this.formatDate(this.masterEndDate)
      this.submittedStartDate = formattedStartDate
      this.submittedEndDate = formattedEndDate
      this.$store.dispatch("instrument/setMasterStartDate", formattedStartDate)
      this.$store.dispatch("instrument/setMasterEndDate", formattedEndDate)
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${month}-${day}-${year}`
    },
    handleToggle(groupName) {
      if (groupName === "DQR") {
        this.$store.dispatch("instrument/toggleDQR")
      } else if (groupName === "DQA") {
        this.$store.dispatch("instrument/toggleDQA")
      }
    },
    getPersonInstruments() {
      this.$store
        .dispatch("instrument/retrieveInstrumentsByPersonId")
        .then(() => {
          this.instruments = {
            ...this.instruments,
            options: this.fullPersonInstruments,
          }
          this.createSiteOptions()
        })
    },
    createSiteOptions() {
      const userInstruments = this.$store.getters["instrument/personInstruments"]
      const explicitSiteCodes = new Set()
      userInstruments.forEach(instr => {
        const sc = (instr.siteCode || "").trim()
        if (sc) {
          explicitSiteCodes.add(sc)
        }
      })
      this.$store.dispatch("armint/retrieveAllSites").then(allSites => {
        const siteOptions = []
        siteOptions.push({
          value: "",
          text: "All My Sites",
        })
        allSites.forEach(siteObj => {
          if (explicitSiteCodes.has(siteObj.siteCode)) {
            siteOptions.push({
              value: siteObj.siteCode,
              text: `${siteObj.siteCode}: ${siteObj.siteName}`,
            })
          }
        })
        this.armsites = {
          ...this.armsites,
          options: siteOptions,
          //value: []
          value: siteOptions[0],
        }
      })
    }
    ,
    filterBySite(data) {
      this.facilities.siteCode = data.value
      this.facilities.value = { value: "" }
      this.instruments.value = { value: "" }
      this.getFacilitiesBySite()
      // this.getInstrumentsBySiteAndFacility()
    },
    getFacilitiesBySite() {
      this.$store
        .dispatch("instrument/retrieveAllFacilities", this.facilities.siteCode)
        .then(data => {
          const facilities = data.map(itm => ({
            value: itm.facilityCode,
            text: itm.facilityName,
          }))
          facilities.unshift({ value: "", text: "All Facilities" })
          this.facilities.options = facilities
        })
        .catch(error => {
          console.error("Error fetching facilities:", error)
        })
    },
    filterByFacility(data) {
      this.instruments = {
        ...this.instruments,
        facilityCode: data.value.value,
        siteCode: this.facilities.siteCode,
        readOnly: false,
        value: { value: "" },
      }
      // this.getInstrumentsBySiteAndFacility()
    },
    filterByInstrument(data) {
      // Handle instrument selection change
    },
    getInstrumentsBySiteAndFacility() {
      const selectedSiteCodes = this.armsites.value.map(site => site.value)
      const selectedFacilityCode = this.facilities.value.value

      const insts = this.fullPersonInstruments
        .filter(itm => (selectedSiteCodes.length === 0 ||
          selectedSiteCodes.includes(itm.siteCode)) &&
          (selectedFacilityCode === "" || itm.facilityCode === selectedFacilityCode))
        .map(itm => itm.instrumentClassCode)

      const allInsts = new Set(insts)
      const itms = Array.from(allInsts).map(itm => ({
        value: itm,
        text: itm,
      }))

      itms.unshift({ value: "", text: "All Instruments" })
      this.instruments.options = itms
    },
    handleQuickFilter(data) {
      if (this.quickFiltered.includes(data)) {
        this.quickFiltered.splice(this.quickFiltered.indexOf(data), 1)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} displayed`, {
          title: `Instrument Displayed`,
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.quickFiltered.push(data)
        this.$bvToast.toast(`Instrument ${data.instrumentClassCode} hidden`, {
          title: `Instrument Hidden`,
          autoHideDelay: 5000,
          variant: "success",
        })
      }
    },
    clearDates(event) {
      event.preventDefault()
      const newKey = this.$store.state.instrument.componentKey + 1
      this.$store.dispatch("instrument/updateComponentKey", newKey)
      this.masterStartDate = null
      this.masterEndDate = null
    },
  },
}
</script>

<template>
  <div id="timelineView">
    <div class="SearchTopBar PageTopBar">
      <b-form>
        <b-row>
          <b-col v-if="showSites">
            <label class="text-light">Sites</label>
            <SitePickerStatic :value="armsites" @change="filterBySite" />
          </b-col>
          <b-col>
            <label class="text-light">Facility</label>
            <PersonFacilityPicker
              :value="facilities"
              :siteCode="facilities.siteCode"
              @change="filterByFacility"
            />
          </b-col>
          <b-col>
            <label class="text-light">Instrument</label>
            <InstrumentPickerStatic
              :value="instruments"
              :options="instruments.options"
              @selectionsChanged="filterByInstrument"
            />
          </b-col>
        </b-row>
        <b-row>
          <Toggles @toggle="handleToggle" />
        </b-row>
        <b-row>
          <b-col cols="12" md="3" />
          <b-col cols="12" md="6">
            <div class="date-picker-container">
              <input
                v-model="masterStartDate"
                class="date-picker"
                type="date"
              />
              <input
                v-model="masterEndDate"
                class="date-picker"
                type="date"
              />
              <button
                class="btn btn-success text-light rounded-0"
                @click="handleSubmitDates($event)"
              >
                Submit Dates
              </button>
              <button
                class="btn btn-danger text-light rounded-0"
                @click="clearDates($event)"
              >
                Clear Dates
              </button>
            </div>
          </b-col>
          <b-col cols="12" md="3" />
        </b-row>
      </b-form>
    </div>
    <b-container fluid class="container-fluid gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0 px-0">
          <ColorCodeLegend />
        </b-col>
      </b-row>
    </b-container>
    <InstrumentList
      v-if="filteredPersonInstruments.length > 0"
      :key="componentKey"
      :instrumentList="filteredPersonInstruments"
      :masterStartDate="submittedStartDate"
      :masterEndDate="submittedEndDate"
    />
  </div>
</template>

<style>
#timelineView {
  font-weight: bold;
}
.date-picker-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.date-picker,
.submit-button {
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.date-picker {
  width: auto;
  flex: 1;
}
.submit-button {
  white-space: nowrap;
}
.results-text {
  text-align: center;
}
</style>
