<script>
import SitePicker from "@/components/global/SitePicker"
import InstrumentClassPicker from "@/components/global/InstrumentClassPicker"
import FacilityPicker from "@/components/global/FacilityPicker"
import MyViewInstrumentList from "@/components/instrument/MyViewInstrumentList.vue"

export default {
  components: {
    SitePicker,
    InstrumentClassPicker,
    FacilityPicker,
    MyViewInstrumentList,
  },
  data() {
    return {
      armsites: {
        id: "armsites",
        label: "Sites",
        placeholder: "All Sites",
        defaultLabel: "All Sites",
        multiple: true,
        value: [],
        options: [],
      },
      instruments: {
        id: "instruments",
        label: "Instruments",
        placeholder: "All Instruments",
        multiple: false,
        value: [],
        options: [],
        retrieveAllOnCreate: true,
      },
      currentFacilityData: {
        id: "currentFacility",
        defaultLabel: "Current Facility: All",
        placeholder: "All Facilities",
        siteCode: "",
        readOnly: true,
        value: "",
        options: [],
      },
      selectedInstrument: null, // [siteCode, facilityCode, instrumentCode, personId, visible]
      resultsFields: [
        {
          key: "siteCode",
          label: "Site",
          sortable: true,
        },
        {
          key: "facilityCode",
          label: "Facility",
          sortable: true,
        },
        {
          key: "instrumentClassCode",
          label: "Instrument",
          sortable: true,
        },
        {
          key: "visibilityToggle",
          label: "Visible",
          sortable: true,
        },
        { 
          key: "actions",
          label: "",
        },
      ],
    }
  },
  computed: {
    personId() {
      return this.$store.getters["auth/personId"]
    },
    personInstruments() {
      return this.$store.getters["instrument/personInstruments"]
    },
    resultsRecords() {
      return this.personInstruments.map(item => ({
        facilityCode: item["facilityCode"],
        instrumentClassCode: item["instrumentClassCode"],
        personId: item["personId"],
        siteCode: item["siteCode"],
        visible: item["visible"],
        nodeId: item["nodeId"],
      }))
    },
  },
  created() {
    this.$store.dispatch("instrument/retrieveInstrumentsByPersonId")
  },
  methods: {
    filterBySite(data) {
      // console.log("filteredBySite")
      this.currentFacilityData = {
        ...this.currentFacilityData,
        siteCode: data.value,
        defaultLabel: data.value,
        readOnly: false,
      }
    },
    filterByFacility(data) {
      // console.log("filteredByFacility")
      this.instruments = {
        ...this.instruments,
        facilityCode: data.value,
        defaultLabel: data.value,
        readOnly: false,
      }
      this.getInstrumentsBySiteAndFacility()
    },
    getInstrumentsBySiteAndFacility() {
      this.$store
        .dispatch("instrument/retrieveInstrumentsBySiteAndFacility", {
          siteCode: this.currentFacilityData.siteCode,
          facilityCode: this.instruments.facilityCode,
        })
        .then((data) => {
          const itms = data.map(itm => ({
            instrumentClassCode: itm["instrumentClassCode"],
            instrumentName: itm["instrumentName"],
          }))
          this.instruments.options = itms
        })
        .catch(e => console.log(e))
    },
    updateInstrumentToAdd(data) {
      if (data !== null) {
        this.selectedInstrument = {
          instrumentClassCode: data.value,
          facilityCode: this.currentFacilityData.value,
          siteCode: this.currentFacilityData.siteCode,
          personId: this.$store.getters["auth/personId"],
          visible: true,
        }
      }
    },
    addInstrument() {
      const personInstruments = this.personInstruments
      const instrumentToAdd = {
        instrumentClassCode: this.selectedInstrument.instrumentClassCode,
        siteCode: this.selectedInstrument.siteCode,
        facilityCode: this.currentFacilityData.value || "",
        personId: this.$store.getters["auth/personId"],
        visible: true,
      }
      // Check for duplicates considering the facility code state
      const contained = personInstruments.filter(i => {
        if (!instrumentToAdd.facilityCode) {
          return (
            instrumentToAdd.instrumentClassCode === i.instrumentClassCode &&
            instrumentToAdd.siteCode === i.siteCode
          ) 
        }
        return (
          instrumentToAdd.instrumentClassCode === i.instrumentClassCode &&
          instrumentToAdd.facilityCode === i.facilityCode &&
          instrumentToAdd.siteCode === i.siteCode
        ) 
      })
      if (contained.length > 0) {
        this.$bvToast.toast(`This Instrument Already Added in your view.`, {
          title: "Duplicate Instrument",
          autoHideDelay: 5000,
          variant: "success",
        })
      } else {
        this.$store
          .dispatch("instrument/updatePersonInstruments", instrumentToAdd)
          .then(() => {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "New Instrument Added",
              autoHideDelay: 5000,
              variant: "success",
            })
            // Reset the selected instrument and clear pickers
            this.selectedInstrument = null
            this.instruments.value = null
            this.currentFacilityData.value = null
            this.armsites.value = null
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    handleRemoveInstrument(data) {
      this.$store
        .dispatch("instrument/deletePersonInstrument", data.nodeId)
        .then(() => {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: `Instrument ${data.instrumentClassCode} Removed`,
            autoHideDelay: 5000,
            variant: "success",
          })
        })
        .catch((error) => {
          console.error("Error deleting instrument:", error)
        })
    },
  },
}
</script>

<template>
  <div id="myView">
    <div class="SearchTopBar PageTopBar">
      <b-form>
        <b-row class="">
          <b-col>
            <label class="text-light">Site</label>
            <SitePicker :value="armsites" @change="filterBySite"/>
          </b-col>
          <b-col>
            <label class="text-light">Facility</label>
            <FacilityPicker v-model="currentFacilityData" @change="filterByFacility"/>
          </b-col>
          <b-col>
            <label class="text-light">Instrument</label>
            <InstrumentClassPicker v-model="instruments" @selectionsChanged="updateInstrumentToAdd"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button variant="success" @click="addInstrument">Add</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div>
      <MyViewInstrumentList :fields="resultsFields" :records="resultsRecords" @remove-instrument="handleRemoveInstrument"/>
    </div>
  </div>
</template>

<style>
#myView {
  font-weight: bold;
}
</style>
