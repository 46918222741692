<script>
import vSelect from "vue-select"

export default {
  components: {
    vSelect,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      key: 0,
      options: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
      ],
      selected: { text: "", value: "" },
    }
  },
  computed: {
    readOnly () {
      return this.value.readOnly !== null ? this.value.readOnly : false
    },
  },
  watch: {
    value: {
      handler(data) {
        this.$emit("input", data)
        this.updateSelected()
      },
      deep: true,
      immediate: true,
    },
    selected(sel) {
      this.value.value = sel ? sel.value : ""
    },
  },
  methods: {
    updateSelected() {
      const match = this.options.find(opt => opt.value === this.value.value)
      this.selected = match ? match : { text: "", value: "" }
    },
    onChange(data) {
      this.$emit("change", data)
    },
  },
}
</script>

<template>
  <b-form-group :id="'input_' + value.id" :description="value.description">
    <v-select 
      v-model="selected"
      :id="value.id"
      :key="key"
      label="text"
      :options="options"
      :disabled="readOnly"
      :clearable="false"
      @change="onChange"
    />
  </b-form-group>
</template>

<style>
</style>
