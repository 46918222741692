<script>
import { mapState } from "vuex"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import DetailsContainer from "@/components/global/DetailsContainer"
import IncidentModal from "@/components/global/IncidentModal"
import Alert from "@/components/global/Alert"
import Config from "@/config"
import validation from "@/mixins/validation"
  
export default {
  name: "Create",
  components: {
    DetailsTopBar,
    DetailsContainer,
    IncidentModal,
    Alert,
  },
  mixins: [validation],
  data() {
    return {
      debug: false,
      canCreateVendor: false,
      displayName: "Create New Vendor",
      alertData: {
        message: "<div class='center'>You currently don't have any permissions to create a Vendor. If access is required, please submit an incident above to App Support to request access</div>",
        variant: "warning",
        html: true,
      },
      coreRows: [
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              data: {
                id: "vendorName",
                label: "Vendor Name",
                value: "",
                readOnly: false,
                state: null,
                message: "Vendor name is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "peoplepicker",
              data: {
                externalUsers: true,
                id: "vendorContactId",
                label: "Vendor Contact",
                value: "",
                state: null,
                message: "Vendor Contact is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "checkbox",
              data: {
                switch: true,
                id: "vendorActive",
                label: "Vendor Active",
                value: true,
                readOnly: !this.canCreateVendor,
                state: null,
                message: "Vendor Active is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 6,
              type: "text",
              data: {
                id: "vendorUrl",
                label: "Vendor URL",
                value: "",
              },
            },
            {
              cols: 6,
              type: "text",
              data: {
                id: "phoneNumber",
                label: "Vendor Phone",
                value: "",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              type: "textarea",
              data: {
                id: "vendorComments",
                label: "Vendor Comments",
                value: "",
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    incidentGroup() {
      return Config.asset.incidentGroup
    },
    topBarItems() {
      const items = []
      if (this.canCreateVendor) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Vendor",
          position: "right",
          variant: "light",
        })
      }
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })
      return items
    },
  },
  created() {
    this.canCreateVendor = this.roles.includes("asset.asset")
    //this.canCreateVendor = true
    if (!this.email) {
      this.$store.dispatch("auth/retrieveEmail")
    }
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        this.createVendor()
      } else if (buttonType === "createIncidentButton") {
        if (!this.email) {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    createVendor() {
      const fields = {}
      this.coreRows.forEach(row => {
        row.items.forEach(item => {
          fields[item.data.id] = item.data.value
        })
      })
  
      const {
        vendorName,
        vendorContactId,
        vendorActive,
        vendorUrl,
        phoneNumber,
        vendorComments,
      } = fields
  

      this.$store.dispatch("asset/getMatchedVendors", {
        vendorName,
      }).then(exists => {
        if (exists) {
          this.$bvToast.toast("This Vendor already exists", {
            title: "Add New Vendor",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }
        this.$store.dispatch("asset/createAssetVendor", {
          vendorName,
          phoneNumber,
          vendorActive,
          vendorContactId,
          vendorUrl,
          vendorComments,
        }).then(vendorObj => {
          this.$bvToast.toast("Vendor created successfully.", {
            title: "Add New Vendor",
            autoHideDelay: 5000,
            variant: "success",
          })
          if (vendorObj && vendorObj.vendorId) {
            setTimeout(() => {
              this.$router.push(`/vendor/details/${vendorObj.vendorId}`)
            }, 500)
          } else {
          }
        })
      }).catch(err => {
        console.error("Error creating vendor:", err)
        this.$bvToast.toast(`Failed to create vendor: ${err.message}`, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        })
      })
    },
    handleValidationResults(errors) {
      if (errors && Array.isArray(errors)) {
      }
    },
  },
}
</script>
<template>
  <div id="CreateVendor">
    <DetailsTopBar
      :items="topBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <Alert v-if="!canCreateVendor" :value="alertData" />
      <template v-else>
        <DetailsContainer
          title="Create New Vendor"
          :rows="coreRows"
          @validationErrors="handleValidationResults"
        />
      </template>
    </div>
    <IncidentModal :group="incidentGroup" :displayName="displayName" />
  </div>
</template>
  
  <style scoped>
  .alert span div.center {
    text-align: center;
  }
  </style>
  
