<script>
import {mapState} from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import AdminSearchResults from "@/components/global/AdminSearchResults.vue"
import IncidentModal from "@/components/global/IncidentModal"
import _ from "underscore"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    AdminSearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Sent from Itar Search",
      collapseRows: true,
      cellCap: 2,
      filters: [
        {
          id: "itarTextSearch",
          type: "text",
          value: "",
          cols: 10,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },
      ],
      sortBy: "itarEccnCode",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("itar", [
      "textSearchFilter",
      "itarSearchResults",
    ]),
    ...mapState("auth", [
      "canViewItarRecord",
      "canEditItarRecord",
      "role",
      "email",
    ]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
    results() {
      return {
        fields: [
          {key: "itarEccnCode", label: "Export Control Code", sortable: true},
          {key: "itarEccnDesc", label: "Export Control Desc", sortable: true},
          {key: "sensitivityRating", label: "Sensitivity Rating", sortable: true},
          {key: "active", label: "Active", sortable: true},
        ],
        records: this.itarSearchResults,
      }
    },
  },
  watch: {
    textSearchFilter(val) {
      _.each(this.filters, (filter) => {
        if (filter.id == "itarTextSearch") {
          filter.value = val
        }
      })
    },
  },
  created() {
    this.initialSearch()

    if (this.debug) {
      console.log("User Access:", {
        role: this.role,
        canViewLocationRecord: this.canViewLocationRecord,
        canEditLocationRecord: this.canEditLocationRecord,
      })
    }
  },
  methods: {
    initialSearch() {
      this.$store.dispatch("itar/performSearch")
    },
    async filterChange(data) {
      if (this.debug) console.log("[Search.vue] filterChange:", data)
      if (data.id == "itarTextSearch") {
        this.$store.dispatch("itar/setTextSearch", data.val)
      }
    },
    buttonOnClick(id) {
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    async filterOnClick(data) {
      if (this.debug) console.log("[Search.vue] filterOnClick:", data)
      if (data.id === "itarTextSearch" && data.val === "submit") {
        this.$store.dispatch("itar/performSearch")
      } else if (data.id === "itarTextSearch" && data.val === "reset") {
        await this.$store.dispatch("itar/resetFilters")
        this.$store.dispatch("itar/performSearch")
      }
    },
    onRowClick(data) {
      this.$router.push({
        path: `/itar/details/${data.itarEccnCode}`,
      })
    },
  },
}
</script>

<template>
  <div id="ItarSearch">
    <SearchTopBar
      :filters="filters"
      @filterChange="filterChange"
      @filterClick="filterOnClick"
      @buttonClick="buttonOnClick"
    />
    <div class="PageContent">
      <AdminSearchResults
        :fields="results.fields"
        :records="results.records"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :collapseRows="collapseRows"
        :cellCap="cellCap"
        @onRowClick="onRowClick"
      />
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
</style>
