<script>
import axios from "axios"
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [ validation ],
  data() {
    return {
      debug: false,
      canEditManufacturer: false,
      incDisplayName: "",
      productId: 0,
      buttonVariant: "light",
      originalRows: [],
      coreRows: [],
      loaded: false,
      error: "",
      detailsExpanded: true,
      initialized: false,
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    detailsTopBarItems() {
      const items = []

      if (this.canEditManufacturer) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "backToSearchButton",
        type: "button",
        label: "↩ Back to Manufacturer Search",
        position: "left",
        variant: this.buttonVariant,
      })

      return items
    },
    incidentGroup() {
      return Config.asset.incidentGroup
    },
  },
  watch: {
    "$route.params.params": {
      immediate: true,
      handler(newId) {
        if (!newId) {
          this.error = "Missing Manufacturer ID Parameter"
        } else if (isNaN(newId)) {
          this.error = "Invalid Manufacturer ID"
        } else {
          const manufacturerId = parseInt(newId)
          if (this.manufacturerId !== manufacturerId) {
            this.manufacturerId = manufacturerId
            this.updateView()
          }
        }
      },
    },
  },
  created() {
    if (this.debug) console.log("[ProductDetails.vue] created")
    this.canEditManufacturer = this.roles.includes("asset.asset")
    // this.canEditManufacturer = true
  },
  methods: {
    async updateView() {
      try {
        if (!this.$route.params.params) {
          this.error = "Missing Manufacturer ID Parameter"
          return
        }
        if (isNaN(this.$route.params.params)) {
          this.error = "Invalid Manufacturer ID Format"
          return
        }
        this.manufacturerId = parseInt(this.$route.params.params)
        await this.loadCurrentRecord()
      } catch (err) {
        console.error("[ManufacturerDetails.vue] Error in updateView:", err)
        this.error = `Failed to update view: ${err.message}`
      }
    },
    loadCurrentRecord() {
      if (this.debug) console.log("[ManufacturerDetails.vue] loadCurrentRecord for ManufacturerId:", this.manufacturerId)

      this.loaded = false
      this.error = ""
      return this.$store.dispatch("asset/getManufacturerById", this.manufacturerId)
        .then(data => {
          console.log(data)
          if (!data) {
            throw new Error("No data returned from API")
          }
          this.incDisplayName = `${data.manufacturerId} - ${data.manufacturerName || ""}`
          this.buildCoreRows(data)
          this.loaded = true
        })
        .catch(error => {
          console.error("[ManufacturerDetails.vue] Error loading manufacturer:", error)
          this.error = `Failed to load manufacturer details: ${error.message}`
          this.loaded = false
          throw error
        })
    },
    buildCoreRows(data) {
      const coreRows = [
        {
          items: [
            {
              data: {
                id: "manufacturerId",
                label: "Manufacturer Id",
                value: data.manufacturerId || "",
                readOnly: true,
                // message: "Name is required.",
                state: null,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "manufacturerName",
                label: "Manufacturer Name",
                value: data.manufacturerName || "",
                readOnly: !this.canEditManufacturer,
                // message: "Name is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "text",
              cols: 6,
            },
            
          ],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerContactId",
                label: "Manufacturer Contact",
                value: data.manufacturerContactId || "",
                readOnly: !this.canEditManufacturer,
                externalUsers: true,
              },
              validation: {
                rules: {
                  required: false,
                },
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "manufacturerUrl",
                label: "Manufacturer URL",
                value: data.manufacturerUrl || "",
                readOnly: !this.canEditManufacturer,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerComments",
                label: "Manufacturer Comments",
                value: data.manufacturerComments || "",
                readOnly: !this.canEditManufacturer,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "phoneNumber",
                label: "Manufacturer Phone",
                value: data.phoneNumber || "",
                readOnly: !this.canEditManufacturer,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "manufacturerActive",
                label: "Manufacturer Active",
                value: data.manufacturerActive || "",
                readOnly: !this.canEditManufacturer,
                switch: true,
              },
              type: "checkbox",
              cols: 6,
            },
          ],
        },
      ]

      this.originalRows = JSON.parse(JSON.stringify(coreRows))
      this.coreRows = coreRows
      console.log(this.coreRows)
      this.loaded = true
    },
    
    onTopBarButtonClick(id) {
      if (id === "saveRecordButton") {
        // Check for permission to edit.
        if (!this.canEditManufacturer) {
          this.$bvToast.toast(`You do not have permission to save changes.`, {
            title: "Manufacturer Edit",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }

        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }

        const payload = {
          manufacturerId: this.manufacturerId,
        }
        
        console.log(payload)
        this.coreRows.forEach(row => {
          row.items.forEach(item => {
            if (item.data.id === "manufacturerId") {
              payload[item.data.id] = item.data.value
            } else {
              payload[item.data.id] = item.data.value
            }
          })
        })

        // Update manufacturer details.
        this.$store.dispatch("asset/updateAssetManufacturerByManufacturerId", {
          manufacturerId: payload.manufacturerId,
          manufacturerName: payload.manufacturerName,
          manufacturerUrl: payload.manufacturerUrl,
          phoneNumber: payload.phoneNumber,
          manufacturerActive: payload.manufacturerActive,
          manufacturerComments: payload.manufacturerComments,
          manufacturerContactId: payload.manufacturerContactId,
        }).then(() => {
          this.$bvToast.toast(`Your modifications have been saved.`, {
            title: "Manufacturer Edit",
            autoHideDelay: 5000,
            variant: "success",
          })
          this.originalRows = JSON.parse(JSON.stringify(this.coreRows))
          return this.loadCurrentRecord()
        })
          .catch((error) => {
            console.error("[ManufacturerDetails.vue] Error saving manufacturer:", error)
            this.$bvToast.toast(`Failed to save modifications: ${error.message}`, {
              title: "Manufacturer Edit", 
              autoHideDelay: 5000,
              variant: "danger",
            })
          })
      } else if (id === "backToSearchButton") {
        this.$router.push(`/manufacturer/search/`)        
      } else if (id === "createIncidentButton") {
        if (this.email === "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      } 
    },
  },
}
</script>

<template>
  <div id="ManufacturerDetails">
    <DetailsTopBar
      :items="detailsTopBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <div v-if="error.length > 0" class="errorPrompt">
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="Manufacturer Details"
            :rows="coreRows" 
            :expanded="detailsExpanded"
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
        </template>
        <div v-else class="errorPrompt">
          Unable to Retrieve Manufacturer Record
        </div>
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      subject="Product Update Request"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
</style>
