import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/product/Search.vue"
import Details from "../views/product/Details.vue"
import Create from "../views/product/Create.vue"

Vue.use(VueRouter)

const prefix = "/product/"

const routes = [
  {
    path: prefix,
    name: "Product",
    component: Search,
  },
  {
    path: `${prefix}create/:params*`,
    name: "Product_Create",
    component: Create,
  },
  {
    path: `${prefix}search/:params*`,
    name: "Product_Search",
    component: Search,
  },
  {
    path: `${prefix}details/:params*`,
    name: "Product_Details",
    component: Details,
  },
]
export default routes
