<script>
import TableGenericList from "@/components/instrument/TableGenericList"
import { mapState } from "vuex"

export default {
  name: "InstTable",
  components: {
    TableGenericList,
  },
  data() {
    return {
      debounceTimer: null,
      instInfo: [],
      insts: [],
      fields: [
        { key: "instNumber", label: "NUMBER", sortable: true },
        { key: "instSite", label: "SITE", sortable: true },
        { key: "instFacility", label: "FACILITY", sortable: true },
        { key: "instClass", label: "INSTRUMENT", sortable: true },
      ],
    }
  },
  computed: {
    ...mapState("instrument", ["personInstruments"]),
    itemsLoaded() {
      return this.instInfo.length > 0
    },
  },
  watch: {
    personInstruments: {
      handler() {
        this.getInstInfo()
      },
      immediate: true,
    },
  },
  created() {
    this.getInstInfo()
  },
  methods: {
    getInstInfo() {
      const filters = this.personInstruments.map((instr) => ({
        siteCode: instr.siteCode,
        facilityCode: instr.facilityCode,
        instrumentClassCode: instr.instrumentClassCode,
      }))
      this.$store
        .dispatch("instrument/retrieveInstDataFromInstEndPoint")
        .then((data) => {
          const instData = data.result
            .map(item => ({
              instSysId: item["sys_id"],
              instSite: item["instrument.next_site_code"],
              instFacility: item["instrument.next_facility_code"],
              instClass: item["instrument.acronym"],
              instNumber: item["number"],
            }))
          const result = instData.filter(instItem => filters.some(filterItem => instItem.instSite === filterItem.siteCode ||
            instItem.instFacility === filterItem.facilityCode ||
            (instItem.instClass).toLowerCase() === filterItem.instrumentClassCode))
          this.instInfo = result
        })
    },
  },
}
</script>

<template>
  <div id="tableView">
    <b-container class="gx-0">
      <b-row class="gx-0">
        <b-col class="gx-0">
          <div id="insts" class="tableContainer">
            <h6 class="font-weight-bold pt-2 px-2 pb-0 mb-0 sectionHeader">INSTs</h6>
            <TableGenericList :fields="fields" :records="instInfo" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.tableContainer {
  background: #95c8fb;
}
</style>
