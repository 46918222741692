<script>
import DatastreamTimeLine from "../../components/instrument/DatastreamTimeLine.vue"
import { mapGetters } from "vuex"

export default {
  name: "InstrumentListItem",
  components: {
    DatastreamTimeLine,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      instrument: null,
      dataStreamInfo: [],
      timelineDataObject: null,
      DQAData: [],
      showInfo: false,
      expandTimeline: false,
      dataLevelCodes: [],
      currentDataLevelCode: "",
      minDate: null,
      maxDate: null,
      originalMinDate: this.minDate, // save original dates
      originalMaxDate: this.maxDate,
    }
  },
  computed: {
    ...mapGetters("instrument", [
      "masterStartDate",
      "masterEndDate",
      "componentKey",
    ]),
    componentKey() {
      return this.$store.getters["instrument/componentKey"]
    },
    masterStartDate() {
      return this.$store.getters["instrument/masterStartDate"]
    },
    masterEndDate() {
      return this.$store.getters["instrument/masterEndDate"]
    },
    isVisible() {
      if (!this.masterStartDate || !this.masterEndDate) {
        return true // If master dates are not set, show all items
      }
      return true
    },
    dataStreamLoaded() {
      return this.dataStreamInfo.length > 0
    },
    TimelineDataLoaded() {
      return Object.keys(this.timelineDataObject) > 0
    },
    DQADataLoaded() {
      return this.DQAData.length > 0
    },
    timelineData() {
      return { dqrs: this.timelineDataObject.dqrs, dqas: this.timelineDataObject.dqas, cmrs: this.timelineDataObject.cmrs, pmrs: this.timelineDataObject.pmrs }
    },
    dataLoaded() {
      return this.timelineDataObject !== null
    },
    datesCleared() {
      return this.$store.getters["instrument/datesCleared"]
    },
  },
  watch: {
    dataLoaded(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.adjustTimelineWidth()
        })
      }
    },
    componentKey(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetTimelineToOriginal()
      }
    },
    masterStartDate(newVal) {
      const date = new Date(newVal)
      if (newVal && !isNaN(date)) {
        if (newVal !== this.minDate) {
          this.minDate = this.formatDateToISO(newVal)
          this.updateTimeline()
        }
        this.$refs.dataStreamTimeLine.setDateRange(this.minDate, this.maxDate)
      }
    },
    masterEndDate(newVal) {
      const date = new Date(newVal)
      if (newVal && !isNaN(date)) {
        if (newVal !== this.maxDate) {
          this.maxDate = this.formatDateToISO(newVal)
          this.updateTimeline()
        }
        this.$refs.dataStreamTimeLine.setDateRange(this.minDate, this.maxDate)
      }
    },
    minDate(newDate) {
      const parsedDate = Date.parse(newDate)
      if (!isNaN(parsedDate)) {
        if (newDate > this.maxDate) {
          this.maxDate = newDate
        }
        if (this.$refs.dataStreamTimeLine) {
          this.$refs.dataStreamTimeLine.setDateRange(this.minDate, this.maxDate)
        }
      }
    },
    maxDate(newDate) {
      const parsedDate = Date.parse(newDate)
      if (!isNaN(parsedDate)) {
        if (newDate < this.minDate) {
          this.minDate = newDate
        }
        if (this.$refs.dataStreamTimeLine) {
          this.$refs.dataStreamTimeLine.setDateRange(this.minDate, this.maxDate)
        }
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.adjustTimelineWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustTimelineWidth)
  },
  created() {
    this.getDataStreamInfo()
  },
  methods: {
    adjustTimelineWidth() {
      this.$nextTick(() => {
        const containerWidth = document.getElementById("test-div").offsetWidth
        if (this.$refs.dataStreamTimeLine && this.$refs.dataStreamTimeLine.$el) {
          this.$refs.dataStreamTimeLine.$el.style.width = `${containerWidth}px`
        }
      })
    },
    updateTimeline() {
      if (this.$refs.dataStreamTimeLine) {
        this.$refs.dataStreamTimeLine.setNewDateRange(this.minDate, this.maxDate)
      }
    },
    resetTimelineToOriginal() {
      if (this.originalMinDate && this.originalMaxDate && this.$refs.dataStreamTimeLine) {
        const startDate = new Date(this.originalMinDate).toISOString().slice(0, 10)
        const endDate = new Date(this.originalMaxDate).toISOString().slice(0, 10)
        this.minDate = startDate
        this.maxDate = endDate
        this.$refs.dataStreamTimeLine.setDateRange(this.minDate, this.maxDate)
      }
    },
    formatDateToISO(dateStr) {
      const [month, day, year] = dateStr.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    formatDate(date) {
      const d = new Date(date)
      const month = `${d.getMonth() + 1}`
      const day = `${d.getDate()}`
      const year = d.getFullYear()
      return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-")
    },
    convertToFormattedDate(isoDateString) {
      if (!isoDateString) return null
      const date = new Date(isoDateString)
      const day = `${date.getDate()}`.padStart(2, "0")
      const month = `${date.getMonth() + 1}`.padStart(2, "0")
      const year = date.getFullYear()
      return `${month}-${day}-${year}`
    },
    getDataStreamInfo() {
      const data = this.$props.value
      this.$store.dispatch("instrument/retrieveInstrumentDataStreamInfos", data).then((data) => {
        const tempData = data.map((item) => {
          return { ...item, start: item["startDate"], end: item["endDate"] }
        })
        this.dataStreamInfo = tempData
        if (tempData.length > 0) {
          this.minDate = this.formatDate(new Date(tempData[0].start))
          this.maxDate = this.formatDate(new Date(tempData[tempData.length - 1].end))
          this.originalMinDate = this.minDate
          this.originalMaxDate = this.maxDate
        }
        this.setDataLevelCodeOptions()
        this.getDataStreams()
      })
    },
    getDataStreams() { //rename to getTimeLineData, and instrument/retrieveTimeLineData
      this.$store.dispatch("instrument/retrieveTimelineData", {
        datastream: this.dataStreamInfo,
        site: this.value.siteCode,
        facility: this.value.facilityCode,
        instrument: this.value.instrumentClassCode,
      }).then((data) => {
        this.timelineDataObject = data
      })
    },
    setDataLevelCodeOptions() {
      const dataLevelTemp = this.dataStreamInfo.map(item => item.dataLevelCode)
      this.dataLevelCodes = Array.from(new Set(dataLevelTemp))
      this.currentDataLevelCode = this.dataLevelCodes[0]
    },
    isCurrentDataLevelCode(data) {
      return this.currentDataLevelCode == data
    },
    toggleTimeline() {
      this.expandTimeline = !this.expandTimeline
      if (this.$refs.dataStreamTimeLine) {
        this.$refs.dataStreamTimeLine.toggleTimeline(this.expandTimeline)
      }
    },
    selectDataLevelCode(code) {
      this.currentDataLevelCode = code
      const filteredData = this.dataStreamInfo.filter((item) => item.dataLevelCode === code)
      if (filteredData.length > 0) {
        this.minDate = this.formatDate(new Date(filteredData[0].start))
        this.maxDate = this.formatDate(new Date(filteredData[filteredData.length - 1].end))
      }
    },
    getDqas() {
      this.$store.dispatch("instrument/retrieveDqaInfo").then((data) => {
        this.DQAData = data
      })
    },
  },
}
</script>
<template>
  <div id="test-div">
    <b-row class="my-3 gx-0 bg-ltblue">
      <b-col class="b-col">
        <b-row class="text-light bg-drkblue ">
          <b-col cols="3">
            <div  class="d-flex align-items-center h-100">
              <p class="mb-0">{{ value.siteCode.toUpperCase() }}  {{ value.facilityCode.toUpperCase() }}  {{ value.instrumentClassCode.toUpperCase() }}</p>
            </div>
          </b-col>
          <b-col cols="9" class="py-2">
            <div class="d-flex justify-content-end align-items-center">
              <div>
                <div class="btn-group">
                  <button v-for="code in dataLevelCodes" :key="code" class="border-0 bg-none text-light mr-1" :class="{ 'activeCode': isCurrentDataLevelCode(code) }" @click="selectDataLevelCode(code)">{{ code }}</button>
                </div>
              </div>
              <div>
                <div class="d-flex justify-items-end align-items-center mr-1">
                  <input v-model="minDate" ref="minDate" type="date" class="input-height" />
                  <span class="mx-2">to</span>
                  <input v-model="maxDate" ref="maxDate" type="date" class="input-height" />
                </div>
              </div>
              <div>
                <div class="btn-group">
                  <button
                    ref="timelineBtn"
                    class="btn btn-success text-light mr-1 rounded-0"
                    @click="toggleTimeline"
                  >
                    {{ expandTimeline ? "Collapse Timeline" : "Expand Timeline" }}
                  </button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="bg-ltblue">
          <div class="p-3 position-relative">
            <div class="w-75">
              <div id="timeline-container" class="timeline-container">
                <DatastreamTimeLine v-if="dataLoaded" id="responsive-timeline" ref="dataStreamTimeLine" class="timeline-class" :value="timelineData" :minDate="minDate" :maxDate="maxDate" :instrumentClassCode="value.instrumentClassCode" :dataStreamInfo="dataStreamInfo"/>
              </div>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
  <!--<div ref="timelineContainer" class="timeline-container"/>-->
</template>
<style scoped>
.timeline-container {
  position: relative;
  width: 1300px;
}
.timeline-class {
  width: 100%;
}
.bg-drkblue {
  background: #032548;
}
.bg-ltblue {
  background: #95c8fb;
}
.bg-none {
  background: none;
}
.input-height {
  height: 38px;
}
.activeCode {
  border-bottom: 2px solid green !important;
}
</style>
