import Vue from "vue"
import VueRouter from "vue-router"
import FCRoutes from "./fc"
import AssetRoutes from "./asset"
import CalibrationRoutes from "./calibration"
import ReportRoutes from "./reports"
import PublicationRoutes from "./publication"
import ResearchHighlightRoutes from "./researchhighlight"
import LocationRoutes from "./location"
import ProductRoutes from "./product"
import ItarRoutes from "./itar"
import InstrumentRoutes from "./instrument"
import Dashboard from "../views/Dashboard.vue"
import VendorRoutes from "./vendor"
import Config from "../config"
import store from "../store"
import Maintenance from "../views/Maintenance.vue"
import ManufacturerRoutes from "./manufacturer"
import AssetTypeRoutes from "./assettypes"


Vue.use(VueRouter)

const SharedRoutes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
  },
]

let routes = []
routes = routes.concat(
  SharedRoutes,
  FCRoutes,
  ResearchHighlightRoutes,
  AssetRoutes,
  ReportRoutes,
  VendorRoutes,
  PublicationRoutes,
  LocationRoutes,
  ProductRoutes,
  InstrumentRoutes,
  CalibrationRoutes,
  ManufacturerRoutes,
  AssetTypeRoutes,
  ItarRoutes,
)

// If App is in maintenance mode only allowed maintenance users in
if (Config.app.maintenanceMode && Config.app.maintenanceUsers.indexOf(store.getters["auth/username"]) == -1) {
  routes.forEach(route => {
    route.component = Maintenance
  })
}

const router = new VueRouter({
  routes,
})

export default router
