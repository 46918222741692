import Vue from "vue"
import Vuex from "vuex"

// Persistant Store
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

import app          from "./app"
import publication  from "./publication"
import researchHighlight from "./researchhighlight.js"
import pubsSearch   from "./pubsSearch"
import fc           from "./fc"
import auth         from "./auth"
import asset        from "./asset"
import armint       from "./armint"
import graphql       from "./graphql"
import campaignPlanner       from "./campaignPlanner"
import location     from "./location"
import calibration      from "./calibration"
import dynamic         from "./dynamic"
import instrument       from "./instrument"
import itar       from "./itar"           

const persistState = createPersistedState({
  paths: ["auth"],
})

export default new Vuex.Store({
  modules: {
    app,
    fc,
    auth,
    asset,
    researchHighlight,
    pubsSearch ,
    publication,
    armint,
    graphql,
    campaignPlanner,
    location,
    instrument,
    calibration,
    dynamic,
    itar,
  },
  plugins: [persistState],
})
