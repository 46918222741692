import axios from "axios"
import Config from "../config"

const itar = {
  namespaced: true,
  state: {
    debug: false,
    textSearchFilter: "",
    shipToFilter: "",
    isActiveFilter: "",
    itarSearchResults: [],
  },
  mutations: {
    setTextSearchFilter(state, payload) {
      state.textSearchFilter = payload
    },
    setIsActiveFilter(state, payload) {
      state.isActiveFilter = payload
    },
    resetFilters(state) {
      state.textSearchFilter = ""
      state.shipToFilter = ""
      state.isActiveFilter = ""
    },
    updateSearchResults(state, payload) {
      const results = []

      for (let i=0;i<payload.length;i++) {
        const itm = payload[i]
        results.push({
          itarEccnCode: itm.itarEccnCode,
          itarEccnDesc: itm.itarEccnDesc,
          sensitivityRating: itm.sensitivityRating,
          active: itm.active,
        })
      }

      state.itarSearchResults = results
    },
  },
  actions: {
    resetFilters: ({commit}) => {
      commit("resetFilters")
    },
    // Search
    performSearch: ({commit, state, rootState}) => {
      let filterString = ""
      let textFilterString = ""

      if (state.textSearchFilter != "") {
        const textFilter = state.textSearchFilter.indexOf("%") == -1 ?
          `%${state.textSearchFilter}%` :
          state.textSearchFilter
        textFilterString = `tsvSearch: { matches: "*${textFilter}*" }`
      }

      // Combine Text Search and Filter Queries
      if (textFilterString !== "") {
        filterString += textFilterString
      }

      // If any filters are applied, prepare string to be joined with query
      if (filterString !== "") {
        filterString = `filter: {${filterString}}`
      }

      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allItarEccnDetails (
              ${filterString}
              orderBy: ITAR_ECCN_CODE_ASC
            ) {
              nodes {
                itarEccnCode
                itarEccnDesc
                sensitivityRating
                active
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }

      if (state.debug) console.log("performSearch query:", opts.data.query)

      axios(opts).then(response => {
        commit("updateSearchResults", response.data.data.allItarEccnDetails.nodes)
      }).catch(error => {
        console.error("Error:", error)
      })
    },
    retrieveProductDetailsByItarEccnCode: ({rootState, state}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data:
        {
          query: `
          {
            allProductControlDetails(filter: {itarEccnCode: {includes: "${payload}"}}) {
              nodes {
                productId
                itarEccnCode
                assignedBy
                assignedDate
                hazardCode
                productDetailByProductId {
                  productId
                  productName
                  productModel
                  productActive
                }
              }
            }
          }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("retrieveProductDetailsByItarEccnCode query:", opts.data.query)
      return axios(opts)
        .then(response => {
          return response.data.data.allProductControlDetails.nodes
        }).catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    // Getters
    retrieveDetailsRecordById: ({rootState, state}, payload) => {
      const opts = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            {
              itarEccnDetailByItarEccnCode(itarEccnCode: "${payload}") {
                itarEccnCode
                itarEccnDesc
                sensitivityRating
                active
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }
      if (state.debug) console.log("retrieveDetailsRecordById query:", opts.data.query)
      return axios(opts)
        .then(response => {
          return response.data.data.itarEccnDetailByItarEccnCode
        }).catch(error => {
          console.error("Error:", error)
          return {}
        })
    },
    // Create
    createItarEccnDetail: ({rootState}, payload) => {
      const itarEccnDesc = payload.itarEccnDesc !== null && (payload.itarEccnDesc != "") ? JSON.stringify(payload.itarEccnDesc) : null
      const itarEccnCode = payload.itarEccnCode !== null && (payload.itarEccnCode != "") ? payload.itarEccnCode : null
      const sensitivityRating = payload.sensitivityRating !== null && payload.sensitivityRating !== "" && !isNaN(payload.sensitivityRating) ? payload.sensitivityRating : null
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createItarEccnDetail (
                input: {
                  itarEccnDetail: {
                    itarEccnDesc: ${itarEccnDesc}
                    sensitivityRating: ${sensitivityRating}
                    itarEccnCode: "${itarEccnCode}"
                    active: ${payload.active}
                  },
                }
              ) {
                itarEccnDetail {
                  itarEccnCode
                  itarEccnDesc
                  sensitivityRating
                  active
                }
              }
            } 
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((resp) => {
        return resp.data.data.createItarEccnDetail.itarEccnDetail.itarEccnCode
      }).catch(error => {
        console.error("Error:", error)
        return error
      })
    },
    // Upserts
    updateItarEccnDetailByItarEccnCode: ({rootState, rootGetters, state}, payload) => {
      const itarEccnDesc = payload.itarEccnDesc !== null && (payload.itarEccnDesc != "") ? JSON.stringify(payload.itarEccnDesc) : null
      const itarEccnCode = payload.itarEccnCode !== null && (payload.itarEccnCode != "") ? payload.itarEccnCode : null
      const sensitivityRating = payload.sensitivityRating !== null && payload.sensitivityRating !== "" && !isNaN(payload.sensitivityRating) ? payload.sensitivityRating : null

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              updateItarEccnDetailByItarEccnCode (
                input: {
                  itarEccnCode: "${itarEccnCode}",
                  itarEccnDetailPatch: {
                    itarEccnDesc: ${itarEccnDesc}
                    sensitivityRating: ${sensitivityRating}
                    active: ${payload.active}
                  }
                }
              ) {
                itarEccnDetail {
                  itarEccnCode
                  itarEccnDesc
                  sensitivityRating
                  active
                }
              }
            }
          `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      }).then((response) => {
        return response
      }).catch(error => {
        console.error("Error:", error)
        return error
      })
    },
    // Setters
    setTextSearch: ({commit}, payload) => {
      commit("setTextSearchFilter", payload)
    },
  },
}

export default itar
