// vuex/modules/file/store.js
//import Config from "../config"

import axios from "axios"
import Cookies from "js-cookie"
import Config from "../config"

const auth = {
  namespaced: true,
  state: {
    DEBUG: false,
    loggedIn: false,
    username: "",
    personId: null,
    email: "",
    role: null,
    roles: [],
    ready: false,
    expires: new Date(),
    warningTimer: null,
    graphqlTicket: "",
    ssoExpires: new Date(),
    graphqlTicketExpires: new Date(),
  },
  getters: {
    username: state => {
      return state.username
    },
    personId: state => {
      return state.personId
    },
    isReady: state => {
      return state.ready
    },
    isExpired: state => {
      /*console.log("Expired Debug:", [
        "state.ssoExpires: " + state.ssoExpires,
        "Date.parse(state.ssoExpires): " + Date.parse(state.ssoExpires),
        "(new Date()): " + (new Date()),
        "Date.parse(state.ssoExpires) <= (new Date()): " + (Date.parse(state.ssoExpires) <= (new Date())),
        "!state.loggedIn: " + !state.loggedIn
      ])*/
      return !state.loggedIn || Date.parse(state.ssoExpires) <= new Date()
    },
    // Used by FC Routes to control access
    canViewFcRecord: state => {
      let result = false

      state.roles.forEach(role => {
        if (role.indexOf("field_campaign.") > -1) {
          result = true
        }
      })

      return result
    },
    // Used by Asset Routes to control access
    canViewAssetRecord: state => {
      return (state.roles.indexOf("asset.asset_view_assets") > -1 ||
              state.roles.indexOf("asset.asset") > -1)
    },
    // Used by Itar Routes to control access
    canViewItarRecord: state => {
      return (state.roles.indexOf("asset.asset") > -1)
    },
    // Used by Itar to limit who can edit to only those with save_export_ctrl access
    canEditItarRecord: state => {
      return (state.roles.indexOf("asset.asset") > -1)
    },
    // Used by Asset Routes to control access
    canCreateAssetRecord: state => {
      return (state.roles.indexOf("asset.asset_save_assets") > -1 ||
              state.roles.indexOf("asset.asset") > -1)
    },
    canViewLocationRecord: state => {
      return state.canViewLocationRecord
    },
    canEditLocationRecord: state => {
      return state.canEditLocationRecord
    },
  },
  mutations: {
    logout(state) {
      state.loggedIn = false
      state.graphQlTicket = ""
      state.personId = ""
      state.username = ""
      state.role = ""
      state.email = ""
      state.ready = false
      state.ssoExpires = new Date()
      state.graphqlTicketExpires = new Date()
      Cookies.remove("graphql.sid", { path: "/" })
    },
    processGraphqlResponse(state, response) {
      if (state.DEBUG) console.log("Auth Response:", response.data)
      state.graphqlTicket = response.data.split(":")[0]
      state.username = response.data.split(":")[1]
      state.personId = response.data.split(":")[2]
      state.role = response.data.split(":")[3]
      state.expires = new Date(0)
      state.expires.setUTCSeconds(response.data.split(":")[4])
      state.ssoExpires = new Date()
      state.ssoExpires.setMinutes(state.ssoExpires.getMinutes() + Config.auth.sessionExpirationMinutes)
      state.loggedIn = true
      state.ready = true

      // Break Out Roles
      const roles = []
      response.data.split(":")[3].split(",").forEach(row => {
        const appParts = row.split("=")

        appParts[1].split(";").forEach(role => {
          roles.push(`${appParts[0]}.${role}`)
        })
      })
      state.roles = roles

      window.location = response.path
    },
    updateEmail(state, email) {
      state.email = email
    },
    resetSessionExpiration(state) {
      if (Date.parse(state.ssoExpires) <= new Date()) {
        state.ssoExpires = new Date()
        state.ssoExpires.setMinutes(state.ssoExpires.getMinutes() + Config.auth.sessionExpirationMinutes)
        /*if (state.warningTimer != null) {
          clearTimeout(state.warningTimer)
        }
        state.warningTimer = setTimeout(() => {
          let remainingMinutes = Config.auth.sessionExpirationMinutes - Config.auth.sessionWarningMinutes
          let extend = confirm("Your session is going to expire in " + remainingMinutes + " minutes. To extend your session, please press OK.")
          if (((new Date).getMilliseconds() <= state.ssoExpires.getMilliseconds()) && extend) {

          }
        }, Config.auth.sessionWarningMinutes * 60 * 1000)*/
      }
    },
  },
  actions: {
    logout: ({ commit }) => {
      commit("logout")
    },
    checkLogin: ({ commit, state }, to) => {
      if (state.DEBUG) console.log("to:", to)
      if (state.DEBUG) console.log("location:", window.location)
      if (state.DEBUG) console.log("location href:", window.location.href)
      if (state.DEBUG) {
        console.log("location pathname:", window.location.pathname) 
      }
      if (state.DEBUG) {
        console.log("url:", window.location.origin + window.location.pathname) 
      }
      if (state.DEBUG) console.debug(`[Action Start] checkLogin`)

      if (window.location.href.indexOf("ticket") > -1) {
        axios.get(`${Config.app.api}/user`, { withCredentials: true }).then(response => {
          console.log("DEBUG:", {
            response,
            user: response.data,
            text: JSON.stringify(response),
          })
          if (response.data === null) {
            axios
              .get(`${Config.app.api}/login`, {
                params: {
                  ticket: encodeURI(window.location.search.replace("?ticket=", "")),
                },
                withCredentials: true,
              }).then(resp => {
                console.log("API Response1: ", resp)
                console.log("API Response2: ", JSON.stringify(resp))
                window.location = `${Config.auth.cas}login?service=${encodeURI(`${Config.auth.serviceName}`)}`
              })
              .catch(error => {
                console.error("API Error:", error)
              })
          } else {
            axios
              .get(`${Config.graphql.host}/authenticate`, {
                params: {
                  service: encodeURI(Config.auth.serviceName),
                  ticket: encodeURI(window.location.search.replace("?ticket=", "")),
                },
              })
              .then(response => {
                if (response.data) {
                  commit("processGraphqlResponse", {
                    data: response.data,
                    path: Cookies.get("landingUrl"), //`${Config.app.host}/${to.hash}`,
                  })
                }
              })
              .catch(error => {
                console.error("checkLogin Error:", error)
              })
          }
        })
      } else {
        Cookies.set("landingUrl", window.location.href)
        if (!state.loggedIn || Date.parse(state.ssoExpires) <= new Date() ) {
          window.location = `${Config.auth.cas}login?service=${encodeURI(`${Config.auth.serviceName}`)}`
        }
      }
      if (state.DEBUG) console.debug(`[Action End] checkLogin`)
    },
    retrieveEmail: ({ rootState, commit, state }) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
              personByPersonId(personId: ${state.personId}) {
                email
              }
            }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.auth.graphqlTicket}`,
        },
      })
        .then(response => {
          if (response.data) {
            commit("updateEmail", response.data.data.personByPersonId.email)
            return response.data.data.personByPersonId.email
          }
        })
        .catch(error => {
          console.error("checkLogin Error:", error)
          return ""
        })
    },
    resetSessionExpiration: ({ commit }) => {
      commit("resetSessionExpiration")
    },
    hasRole: ({state}, role) => {
      return state.roles.indexOf(role) > -1
    },
  },
}

export default auth
