<script>
import { mapState, mapActions } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal" 
import Config from "@/config"
import validation from "@/mixins/validation"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [
    validation,
  ],
  props: {
    id: { default: null, type: Number },
  },
  data() { 
    return {
      debug: false,
      buttonVariant: "light",
      itarExpanded: true,
      displayName: "Sent from Create ITAR/ECCN",
      coreRows: [
        {
          items: [
            {
              data: {
                id: "itarEccnCode",
                label: "Export Control Code",
                value: "",
                readOnly: false,
                message: "Export Control Desc cannot be over 50 characters",
                state: null,
              },
              validation: {
                rules: {
                  required: false,
                  maxLength: 50,
                },
              },
              type: "text",
              cols: 4,
            },
            {
              data: {
                id: "sensitivityRating",
                label: "Sensitivity Rating",
                value: "",
                readOnly: false,
                state: null,
              },
              type: "sensitivityratingpicker",
              cols: 4,
            },
            {
              data: {
                id: "active",
                label: "Active",
                value: true,
                readOnly: false,
                switch: true,
              },
              type: "checkbox",
              cols: 4,
            },
            {
              data: {
                id: "itarEccnDesc",
                label: "Export Control Desc",
                value: "",
                readOnly: false,
                message: "Export Control Desc cannot be over 2147483647 characters",
                state: null,
              },
              validation: {
                rules: {
                  required: false,
                  maxLength: 2147483647,
                },
              },
              type: "textarea",
              cols: 12,
            },
          ],
        },
      ],
    }
  },

  computed: {
    ...mapState("auth", [
      "email",
      "personId",
      "username",
    ]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
  
    editModalFooterButtons() {
      const btns = {
        rightButtons: [
          {
            id: "cancelButton",
            text: "Cancel",
            variant: "secondary",
          },
          {
            id: "saveButton",
            text: "Save",
            variant: "primary",
          },
        ],
        
      }

      return btns
    },
    detailsTopBarItems() {
      const items = []

      items.push({
        id: "saveRecordButton",
        type: "button",
        label: "Save",
        position: "right",
        variant: this.buttonVariant,
      })
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })

      return items
    },
    templateData() {
      const results = []

      this.sections.forEach(section => {
        if (section.type == "detailsContainer") {
          section.rows.forEach(row => {
            row.items.forEach(itm => {
              if (itm.template) {
                results.push({
                  type: itm.type,
                  value: itm.data.value,
                  id: itm.data.id,
                  label: itm.data.label,
                  data: {
                    id: itm.data.id.replace("field_", ""),
                    readOnly: false,
                    switch: true,
                    clearable: true,
                    value: itm.data.value,
                  },
                })
              }
            })
          })
        }
      })

      return results
    },

  },
  watch: {
    coreRows: {
      handler(data) {
        console.log(data)
        // data.forEach(row => {
        //   row.items.forEach(item => {
        //     if (item.type == "publicationstatuspicker") {
        //       item.data.value = this.keywordStatusFilter
        //     }
        //   })
        // })
      },
    },
  },
  methods: {

    onTopBarButtonClick(id) {
      if (this.debug) console.log("[Details.vue onTopBarButtonClick] id:", id)
      if (id == "saveRecordButton") {
        this.saveRecord()
      } else if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    saveRecord() {
      if (!this.checkSchemaState(this.coreRows)) {
        this.showErrorSummary()
        return
      }

      if (this.debug) console.log("[CreateItar.vue saveRecord] Data to Save:", data)
      const fields = []
      this.coreRows.forEach(row => {
        row.items.forEach(itm => {
          fields[itm.data.id] = itm.data.value !== null && itm.data.value !== undefined && typeof(itm.data.value) == "object" ? itm.data.value.value : itm.data.value
        })
      })

      const itarEccn = fields.itarEccnCode

      this.$store.dispatch("itar/retrieveDetailsRecordById", itarEccn).then(exists => {
        if (exists) {
          this.$bvToast.toast("This Export Control already exists", {
            title: "ITAR/ECCN Creation Attempt",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }
        this.$store.dispatch("itar/createItarEccnDetail", fields).then(itarObj => {
          this.$bvToast.toast("ITAR/ECCN created successfully.", {
            title: "ITAR/ECCN Created",
            autoHideDelay: 5000,
            variant: "success",
          })
          if (itarObj) {
            setTimeout(() => {
              this.$router.push(`/itar/details/${itarObj}`)
            }, 500)
          } else {
            this.$bvToast.toast("Failed to create ITAR/ECCN.", {
              title: "ITAR/ECCN Creation Attempt",
              autoHideDelay: 5000,
              variant: "danger",
            })
          }
        })
      }).catch(err => {
        console.error("Error creating ITAR/ECCN:", err)
        this.$bvToast.toast(`Failed to create ITAR/ECCN: ${err.message}`, {
          title: "Error",
          autoHideDelay: 5000,
          variant: "danger",
        })
      })
    },
  },
}
</script>

<template>
  <div id="createItarEccn">
    <DetailsTopBar :items="detailsTopBarItems" @buttonClick="onTopBarButtonClick" />
    <div class="PageContent">
      <template>
        <DetailsContainer 
          title="Create ITAR/ECCN" 
          :rows="coreRows"
          :expanded="itarExpanded"
          :refreshCount="refreshCount"
          @expandToggle="itarExpanded = !itarExpanded"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>

<style>
.minDateWidth {
  width: 200px;
}
</style>
