<script>
import { mapState } from "vuex"
import FormModal from "@/components/global/FormModal"
import vSelect from "vue-select"
import * as XLSX from "xlsx"

export default {
  name: "AdminSearchResults",
  components: {
    FormModal,
    vSelect,
  },
  props: {
    fields:     { type: Array, required: true },
    records:    { type: Array, required: true },
    groupEdit:  { default: false, type: Boolean },
    sortBy:     { default: "", type: String },
    sortDesc:   { default: false, type: Boolean },
    collapseRows: { default: false, type: Boolean },
    cellCap:    { default: 4, type: Number },
    visibleFields: { type: Array, default: () => [] },
  },
  data() {
    return {
      debug: false,
      resultsPerPage: 20,
      currentPage: 1,
      selectedRows: [],
      groupEditForm: { rows: [] },
      groupNextSite: "",
      groupNextFacility: "",
      groupNextComment: "",
      groupNextStatus: "proposed",
      groupNextStartDate: "",
      groupNextEndDate: "",
      pageSizeOptions: [20, 50, 100, 250, 500],
    }
  },
  computed: {
    ...mapState("armid", ["searchResults"]),
    totalRows() {
      return this.records.length
    },
    startRec() {
      return (this.currentPage * this.resultsPerPage) - this.resultsPerPage + 1
    },
    endRec() {
      const pageMax = this.currentPage * this.resultsPerPage
      return pageMax <= this.totalRows ? pageMax : this.totalRows
    },
  },
  watch: {
    records() {
      this.currentPage = 1
    },
    resultsPerPage() {
      // Force re-evaluation when page size changes
      this.$forceUpdate()
    },
  },
  created() {
  },
  methods: {
    onRowClick(data) {
      this.$emit("onRowClick", data)
    },
    onGroupEditClick() {
      if (this.debug) console.log("Selections:", this.selectedRows)
    },
    formatCell(data) {
      return this.collapseRows && this.cellLines(data).length > this.cellCap ?
        this.cellLines(data).slice(0, this.cellCap - 1).join("\n") :
        data.value
    },
    cellLines(data) {
      return data.value.toString().split("\n")
    },
    displayGroupEditForm() {
      if (this.selectedRows.length == 0) {
        alert("No records selected")
      } else {
        this.groupEditForm = {
          id: "groupEditForm",
          title: "Group Edit",
          rows: [
            {
              id: "statusData",
              items: [
                {
                  data: {
                    id: "status",
                    label: "Status:",
                    value: this.groupNextStatus,
                    options: [
                      {
                        value: "proposed",
                        text: "Proposed",
                        selected: true,
                      },
                      {
                        value: "approved",
                        text: "Approved",
                      },
                    ],
                  },
                  type: "selectpicker",
                  cols: 6,
                },
                {
                  data: { id: "gap1" },
                  type: "gap",
                  cols: 6,
                },
              ],
            },
            {
              id: "locData",
              items: [
                {
                  data: {
                    id: "location",
                    label: "Next Location:",
                    value: this.groupNextSite,
                    defaultLabel: "Please select a location",
                    options: [],
                  },
                  type: "sitepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "facility",
                    label: "Next Facility",
                    siteCode: this.groupNextSite,
                    value: this.groupNextFacility,
                    readOnly: this.groupNextSite == "",
                    defaultLabel: "Please select a facility",
                    options: [],
                  },
                  type: "facilitypicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "dateData",
              items: [
                {
                  data: {
                    id: "startDate",
                    label: "Install Date",
                    value: this.groupNextStartDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
                {
                  data: {
                    id: "endDate",
                    label: "Removal Date",
                    value: this.groupNextEndDate,
                  },
                  type: "datepicker",
                  cols: 6,
                },
              ],
            },
            {
              id: "commentData",
              items: [
                {
                  data: {
                    id: "comment",
                    label: "Comments",
                    value: this.groupNextComment,
                    readOnly: false,
                  },
                  type: "text",
                  cols: 12,
                },
              ],
            }],
        }

        this.$bvModal.show("groupEditForm")
      }
        
    },
    saveGroupEditForm(data) {
      let location = ""
      let facility = ""
      let comment = ""
      let startDate = ""
      let endDate = ""
      let status = ""

      for (let r=0;r<data.rows.length;r++) {
        const row = data.rows[r]
        for (let i=0;i<row.items.length;i++) {
          const item = row.items[i].data
          if (item.id == "location") {
            location = item.value
          } else if (item.id == "facility") {
            facility = item.value
          } else if (item.id == "comment") {
            comment = item.value
          } else if (item.id == "startDate") {
            startDate = item.value
          } else if (item.id == "endDate") {
            endDate = item.value
          } else if (item.id == "status") {
            status = item.value
          }
        }
      }

      // Handle Save
      for (let i=0;i<this.selectedRows.length;i++) {
        const armid = this.selectedRows[i].split("_")[0]
        const nodeId = this.selectedRows[i].split("_")[1]

        this.$store
          .dispatch("armid/updateArmidInfoByNodeId", {
            armid,
            nodeId,
            status,
            siteCode: location,
            facilityCode: facility,
            startDate,
            endDate,
            comment,
          })
          .then(success => {
            if (success) {
            // Do Nothing
            }
          })
      }

      // Reload Search Results

      if (this.debug) {
        console.log("saveGroupEditForm:", {
          selected: this.selectedRows,
          location,
          facility,
          startDate,
          endDate,
          status,
          comment,
        }) 
      }
    },
    fetchAllRecords() {
      // Return a promise with the complete dataset
      return Promise.resolve(this.records)
    },
    exportToExcel() {
      this.fetchAllRecords().then(allItems => {
        const columnHeaders = this.fields.map(field => field.label || field.key)
        
        // Map items to the format needed for Excel
        const data = allItems.map(item => {
          return this.fields.map(field => {
            const keys = field.key.split(".")
            let value = item
            for (const key of keys) {
              if (value && value.hasOwnProperty(key)) {
                value = value[key]
              } else {
                value = undefined
                break
              }
            }
            return value
          })
        })
        
        // Create the worksheet
        const worksheet = XLSX.utils.aoa_to_sheet([columnHeaders, ...data])
        
        // Create workbook and append worksheet
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")
        
        // Create blob and download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" })
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob)
        link.download = "search_results.xlsx"
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    onPageSizeChanged() {
      // Reset to page 1 when changing page size
      this.currentPage = 1
      
      // Force update to ensure view refreshes
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
  },
}
</script>
<template>
  <div class="SearchResults table">
    <b-form-checkbox-group v-model="selectedRows" size="lg" name="selectedRows">
      <b-table 
        :fields="fields" 
        :items="records"
        :per-page="resultsPerPage"
        :current-page="currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        bordered
        hover
        head-variant="dark"
        tbody-td-class="resultsCell"
        class="resultsTable"
        @row-clicked="onRowClick"
      >
        <template #cell()="data">
          {{ formatCell(data) }}
        </template>
        <template v-if="canEdit" v-slot:cell(selected)="data">
          <b-form-checkbox :value="data.item.armid + '_' + data.item.nextNodeId" />
        </template>
      </b-table>
    </b-form-checkbox-group>

    <div class="tableControls">
      <div class="resultCount">
        Displaying {{ startRec }} - {{ endRec }} of {{ totalRows }} rows
      </div>
      <div class="resultCount pageSizeContainer">
        <span>Page Size:</span>
        <v-select
          v-model="resultsPerPage"
          :options="pageSizeOptions"
          :clearable="false"
          class="pageSizeSelect"
        />
      </div>
    </div>

    <!-- Table controls (bottom row) -->
    <div class="tableControls bottomSection">
      <div class="bottomButtons">
        <b-button
          v-if="canEdit && groupEdit"
          variant="primary"
          @click="displayGroupEditForm"
        >
          Group Edit
        </b-button>
        <b-dropdown text="Actions" variant="custom-dropdown">
          <b-dropdown-item @click="exportToExcel">Export to Excel</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="paginationContainer">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="resultsPerPage"
          aria-controls="my-table"
        />
      </div>
    </div>
    <FormModal
      v-if="groupEdit"
      v-model="groupEditForm"
      id="groupEditForm"
      @ok="saveGroupEditForm"
    />
  </div>
</template>

<style>
/************************
**      Pagination     **
************************/
.table > ul {
  float: right;
}

/************************
**        Table        **
************************/
.table table {
  border-collapse: separate;
  border-spacing: 1px 3px;
  border: none;
}

/************************
**     Table Header    **
************************/
.table table thead tr th {
  padding-left: 15px;
  background-color: #194173 !important;
  color: #fff;
  min-width: 120px;
}
.table table thead tr th:first-child {
  border-top-left-radius: 5px;
}
.table table thead tr th:last-child {
  border-top-right-radius: 5px;
}

/************************
**      Table Rows     **
************************/
.table table tbody tr td {
  background-color: #fff;
  padding-left: 15px;
  white-space: pre-line;
}
.table table tbody tr:hover td { 
  background-color: #C5DFED !important; 
  cursor: pointer;
}
.table table tbody tr td:first-child {
  text-align: center;
}

/************************
**  Table Controls     **
************************/
.table .tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table .resultCount {
  margin-right: 20px;
}

.table .pageSizeContainer {
  display: flex;
  align-items: center;
}

.table .pageSizeContainer span {
  margin-right: 8px;
}

.table .pageSizeSelect {
  width: 160px;
}

.table .bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table .paginationContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.table .bottomButtons button {
  border-color: #6c757d;
}
.table .bottomButtons button.btn-link {
  border: none;
}

/************************
**        Other        **
************************/
.table .emptyTableNote {
  text-align: center;
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 10px;
}

.table.b-table > thead > tr > [aria-sort=none],
.table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' viewBox='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='white' opacity='1' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e") !important;
}
</style>
