<script>
import {mapState} from "vuex"
import SearchTopBar from "@/components/global/SearchTopBar"
import AdminSearchResults from "@/components/global/AdminSearchResults"
import _ from "underscore"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"

export default {
  components: {
    SearchTopBar,
    AdminSearchResults,
    IncidentModal,
  },
  data() {
    return {
      debug: false,
      displayName: "Manufacturer Search",
      collapseRows: true,
      cellCap: 2,
      manufacturers: [],
      filters: [
        {
          id: "manufacturerTextSearch",
          type: "text",
          value: "",
          cols: 6,
        },
        {
          id: "createIncidentButton",
          type: "button",
          value: "",
          label: "Create Incident",
          cols: 2,
          variant: "light",
        },

      ],
      sortBy: "manufacturerId",
      sortDesc: false,
    }
  },
  computed: {
    ...mapState("auth", ["role", "email"]),
    incidentGroup() {
      return Config.itar.incidentGroup
    },
    results() {
      return {
        fields: [
          {key: "manufacturerId", label: "ID", sortable: true},
          {key: "manufacturerName", label: "Manufacturer Name", sortable: true},
          {key: "manufacturerContactName", label: "Contact Name", sortable: true},
          {key: "manufacturerUrl", label: "Manufacturer URL", sortable: true},
          {key: "manufacturerComments", label: "Comments", sortable: true},
          {key: "manufacturerActive", label: "Active", sortable: true},
        ],
        records: this.manufacturers,
      }
    },
  },
  created() {
    this.initialSearch()
  },
  methods: {
    async initialSearch() {
      try {
        const manufacturers = await this.$store.dispatch("asset/getManufacturers")
        console.log(manufacturers)
        this.manufacturers = manufacturers.map(manufacturer => ({
          manufacturerId: manufacturer.manufacturerId,
          manufacturerName: manufacturer.manufacturerName,
          manufacturerContactName: manufacturer.manufacturerContactName,
          manufacturerUrl: manufacturer.manufacturerUrl,
          manufacturerComments: manufacturer.manufacturerComments,
          manufacturerActive: manufacturer.manufacturerActive,
        }))
      } catch (error) {
        console.error("Error fetching manufacturer data:", error)
      }
    },
    async filterChange(data) {
      if (this.debug) console.log("[ManufacturerSearch.vue] filterChange:", data)
      if (data.id === "manufacturerTextSearch") {
        this.searchText = data.val
      }
    },
    buttonOnClick(id) {
      console.log(id)
      if (this.debug) console.log("buttonOnClick:", id)
      if (id == "createIncidentButton") {
        if (this.email == "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    async filterOnClick(data) {
      if (this.debug) console.log("[ManufacturerSearch.vue] filterOnClick:", data)
      if (data.id === "manufacturerTextSearch" && data.val === "submit") {
        // Filter manufacturers  when enter is pressed
        const searchText = this.searchText?.toLowerCase() || ""
        const manufacturers = await this.$store.dispatch("asset/getManufacturers")
        this.manufacturers = manufacturers
          .filter(manufacturer => manufacturer.manufacturerName?.toLowerCase().includes(searchText) ||
            // manufacturer.manufacturerId?.toLowerCase().includes(searchText) ||
            manufacturer.manufacturerContactName?.toLowerCase().includes(searchText))
          .map(manufacturer => ({
            manufacturerId: manufacturer.manufacturerId,
            manufacturerName: manufacturer.manufacturerName,
            manufacturerContactName: manufacturer.manufacturerContactName,
            manufacturerUrl: manufacturer.manufacturerUrl,
            manufacturerComments: manufacturer.manufacturerComments,
            manufacturerActive: manufacturer.manufacturerActive,
          }))
      } else if (data.id === "manufacturerTextSearch" && data.val === "reset") {
        // Reset the search when reset is clicked
        this.searchText = ""
        await this.initialSearch()
      }
    },
    onRowClick(data) {
      if (data && data.manufacturerId) {
        this.$router.push({
          path: `/manufacturer/details/${data.manufacturerId}`,
        })
      }
    },
  },
}
</script>

<template>
  <div id="ManufacturerSearch">
    <SearchTopBar
      :filters="filters"
      @filterChange="filterChange"
      @filterClick="filterOnClick"
      @buttonClick="buttonOnClick"
    />
    <div class="PageContent">
      <AdminSearchResults
        :fields="results.fields"
        :records="results.records"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :collapseRows="collapseRows"
        :cellCap="cellCap"
        @onRowClick="onRowClick"
      />
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>
