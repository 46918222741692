<script>
import { mapState } from "vuex"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import Alert from "@/components/global/Alert"
import DetailsContainer from "@/components/global/DetailsContainer"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import validation from "@/mixins/validation"
  
export default {
  name: "Create",
  components: {
    DetailsTopBar,
    DetailsContainer,
    IncidentModal,
    Alert,
  },
  mixins: [validation],
  data() {
    return {
      debug: false,
      canCreateManufacturer: false,
      displayName: "Create New Manufacturer",
      alertData: {
        message: "<div class='center'>You currently don't have any permissions to create a Manufacturer. If access is required, please submit an incident above to App Support to request access</div>",
        variant: "warning",
        html: true,
      },
      coreRows: [
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "text",
              data: {
                id: "manufacturerName",
                label: "Manufacturer Name",
                value: "",
                readOnly: false,
                state: null,
                message: "Manufacturer Name is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "peoplepicker",
              data: {
                externalUsers: true,
                id: "manufacturerContactId",
                label: "Manufacturer Contact",
                value: "",
                state: null,
                message: "Manufacturer Contact is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              validation: {
                rules: {
                  required: true,
                },
              },
              type: "checkbox",
              data: {
                switch: true,
                id: "manufacturerActive",
                label: "Manufacturer Active",
                value: true,
                readOnly: !this.canCreateManufacturer,
                state: null,
                message: "Manufacturer active is required",
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 6,
              type: "text",
              data: {
                id: "manufacturerUrl",
                label: "Manufacturer URL",
                value: "",
              },
            },
            {
              cols: 6,
              type: "text",
              data: {
                id: "phoneNumber",
                label: "Manufacturer Phone",
                value: "",
                state: null,
              },
            },
          ],
        },
        {
          items: [
            {
              cols: 12,
              type: "textarea",
              data: {
                id: "manufacturerComments",
                label: "Manufacturer Comments",
                value: "",
                state: null,
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
  
    incidentGroup() {
      return Config.asset.incidentGroup
    },
  
    topBarItems() {
      const items = []
      // show create only if user can create
      if (this.canCreateManufacturer) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Create Manufacturer",
          position: "right",
          variant: "light",
        })
      }
      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: "light",
      })
      return items
    },
  },
  created() {
    this.canCreateManufacturer = this.roles.includes("asset.asset")
    //this.canCreateManufacturer = true
    if (!this.email) {
      this.$store.dispatch("auth/retrieveEmail")
    }
  },
  methods: {
    onTopBarButtonClick(buttonType) {
      if (buttonType === "saveRecordButton") {
        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }
        this.createManufacturer()
      } else if (buttonType === "createIncidentButton") {
        if (!this.email) {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
    createManufacturer() {

      const fields = {}
      this.coreRows.forEach(row => {
        row.items.forEach(item => {
          fields[item.data.id] = item.data.value
        })
      })

      const {
        manufacturerName,
        phoneNumber,
        manufacturerActive,
        manufacturerContactId,
        manufacturerUrl,
        manufacturerComments,
      } = fields
      this.$store
        .dispatch("asset/getMatchedManufacturers", { manufacturerName })
        .then(exists => {
          if (exists) {
            this.$bvToast.toast("This Manufacturer already exists", {
              title: "Add New Manufacturer",
              autoHideDelay: 5000,
              variant: "danger",
            })
            return
          }
          this.$store.dispatch("asset/createAssetManufacturer", {
            manufacturerName,
            phoneNumber,
            manufacturerActive,
            manufacturerContactId,
            manufacturerUrl,
            manufacturerComments,
          }).then(manufacturerObj => {
            this.$bvToast.toast("Manufacturer created successfully.", {
              title: "Add New Manufacturer",
              autoHideDelay: 5000,
              variant: "success",
            })
            if (manufacturerObj && manufacturerObj.manufacturerId) {
              setTimeout(() => {
                this.$router.push(`/manufacturer/details/${manufacturerObj.manufacturerId}`)
              }, 500)
            }
          })
        })
        .catch(err => {
          console.error("Error creating manufacturer:", err)
          this.$bvToast.toast(`Failed to create manufacturer: ${err.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            variant: "danger",
          })
        })
    },
    handleValidationResults(errors) {
      if (!errors || !Array.isArray(errors)) return
    },
  },
}
</script>

<template>
  <div id="CreateManufacturer">
    <DetailsTopBar
      :items="topBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <Alert v-if="!canCreateManufacturer" :value="alertData" />
      <template v-else>
        <DetailsContainer
          title="Create New Manufacturer"
          :rows="coreRows"
          @validationErrors="handleValidationResults"
        />
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      :displayName="displayName"
    />
  </div>
</template>
  
  <style scoped>
  .alert span div.center {
    text-align: center;
  }
  </style>
  
