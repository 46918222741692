import Vue from "vue"
import VueRouter from "vue-router"
import Search from "../views/AssetTypes/Search.vue"
import Details from "../views/AssetTypes/Details.vue"
import Create from "../views/AssetTypes/Create.vue"


Vue.use(VueRouter)

const prefix = "/assettypes/"

const routes = [
  {
    path: prefix,
    name: "Asset Types",
    component: Search,
  },
  {
    path: `${prefix}create/:params*`,
    name: "AssetTypes_Create",
    component: Create,
  },

  {
    path: `${prefix}search/:params*`,
    name: "AssetTypes_Search",
    component: Search,
  },
  {
    path: `${prefix}details/:params*`,
    name: "AssetType_Details",
    component: Details,
  },
]

export default routes
