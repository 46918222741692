<script>
import axios from "axios"
import { mapState } from "vuex"
import DetailsContainer from "@/components/global/DetailsContainer"
import DetailsTopBar from "@/components/global/DetailsTopBar"
import IncidentModal from "@/components/global/IncidentModal"
import Config from "@/config"
import validation from "@/mixins/validation"
import asset from "../../store/asset"

export default {
  components: {
    DetailsContainer,
    DetailsTopBar,
    IncidentModal,
  },
  mixins: [ validation ],
  data() {
    return {
      debug: false,
      canEditVendor: false,
      incDisplayName: "",
      productId: 0,
      buttonVariant: "light",
      originalRows: [],
      coreRows: [],
      loaded: false,
      error: "",
      detailsExpanded: true,
      initialized: false,
    }
  },
  computed: {
    ...mapState("auth", ["roles", "email"]),
    detailsTopBarItems() {
      const items = []

      if (this.canEditVendor) {
        items.push({
          id: "saveRecordButton",
          type: "button",
          label: "Save Record",
          position: "right",
          variant: this.buttonVariant,
        })
      }

      items.push({
        id: "createIncidentButton",
        type: "button",
        label: "Create Incident",
        position: "right",
        variant: this.buttonVariant,
      })

      items.push({
        id: "backToSearchButton",
        type: "button",
        label: "↩ Back to Vendor Search",
        position: "left",
        variant: this.buttonVariant,
      })

      return items
    },
    incidentGroup() {
      return Config.asset.incidentGroup
    },
  },
  watch: {
    "$route.params.params": {
      immediate: true,
      handler(newId) {
        if (!newId) {
          this.error = "Missing Vendor ID Parameter"
        } else if (isNaN(newId)) {
          this.error = "Invalid Vendor ID"
        } else {
          const vendorId = parseInt(newId)
          if (this.vendorId !== vendorId) {
            this.vendorId = vendorId
            this.updateView()
          }
        }
      },
    },
  },
  created() {
    if (this.debug) console.log("[VendorDetails.vue] created")
    this.canEditVendor = this.roles.includes("asset.asset")
  },
  methods: {
    async updateView() {
      try {
        if (!this.$route.params.params) {
          this.error = "Missing Vendor ID Parameter"
          return
        }
        
        if (isNaN(this.$route.params.params)) {
          this.error = "Invalid Vendor ID Format"
          return
        }

        this.vendorId = parseInt(this.$route.params.params)
        await this.loadCurrentRecord()
      } catch (err) {
        console.error("[VendorDetails.vue] Error in updateView:", err)
        this.error = `Failed to update view: ${err.message}`
      }
    },
    loadCurrentRecord() {
      if (this.debug) console.log("[VendorDetails.vue] loadCurrentRecord for VendorId:", this.vendorId)

      this.loaded = false
      this.error = ""

      return this.$store.dispatch("asset/getVendorById", this.vendorId)
        .then(data => {
          console.log(data)
          if (!data) {
            throw new Error("No data returned from API")
          }

          this.incDisplayName = `${data.vendorId} - ${data.vendorName || ""}`
          this.buildCoreRows(data)
          this.loaded = true
        })
        .catch(error => {
          console.error("[VendorDetails.vue] Error loading product:", error)
          this.error = `Failed to load Vendor details: ${error.message}`
          this.loaded = false
          throw error
        })
    },
    buildCoreRows(data) {
      const coreRows = [
        {
          items: [
            {
              data: {
                id: "vendorId",
                label: "Vendor Id",
                value: data.vendorId || "",
                readOnly: true,
                // message: "Name is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "vendorName",
                label: "Vendor Name",
                value: data.vendorName || "",
                readOnly: !this.canEditVendor,
                // message: "Name is required.",
                state: null,
              },
              // validation: {
              //   rules: {
              //     required: true,
              //   },
              // },
              type: "text",
              cols: 6,
            },
            
          ],
        },
        {
          items: [
            {
              data: {
                id: "vendorContactId",
                label: "Vendor Contact",
                value: data.vendorContactId || "",
                readOnly: !this.canEditVendor,
                externalUsers: true,
              },
              validation: {
                rules: {
                  required: false,
                },
              },
              type: "peoplepicker",
              cols: 6,
            },
            {
              data: {
                id: "vendorUrl",
                label: "Vendor URL",
                value: data.vendorUrl || "",
                readOnly: !this.canEditVendor,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "vendorComments",
                label: "Vendor Comments",
                value: data.vendorComments || "",
                readOnly: !this.canEditVendor,
              },
              type: "text",
              cols: 6,
            },
            {
              data: {
                id: "phoneNumber",
                label: "Vendor Phone",
                value: data.phoneNumber || "",
                readOnly: !this.canEditVendor,
              },
              type: "text",
              cols: 6,
            },
          ],
        },
        {
          items: [
            {
              data: {
                id: "vendorActive",
                label: "Vendor Active",
                value: data.vendorActive ?? false,
                readOnly: !this.canEditVendor,
                switch: true,
              },
              type: "checkbox",
              cols: 6,
            },
          ],
        },
      ]

      this.originalRows = JSON.parse(JSON.stringify(coreRows))
      this.coreRows = coreRows
      console.log(this.coreRows)
      this.loaded = true
    },
    
    onTopBarButtonClick(id) {
      if (id === "saveRecordButton") {
        // Check for permission to edit.
        if (!this.canEditVendor) {
          this.$bvToast.toast(`You do not have permission to save changes.`, {
            title: "Vendor Edit",
            autoHideDelay: 5000,
            variant: "danger",
          })
          return
        }

        if (!this.checkSchemaState(this.coreRows)) {
          this.showErrorSummary()
          return
        }

        const payload = {
          vendorId: this.vendorId,
        }
        
        console.log(payload)
        this.coreRows.forEach(row => {
          row.items.forEach(item => {
            if (item.data.id === "vendorId") {
              payload[item.data.id] = item.data.value
            } else {
              payload[item.data.id] = item.data.value
            }
          })
        })

        // Update Vendor details.
        this.$store.dispatch("asset/updateAssetVendorByVendorId", {
          vendorId: payload.vendorId,
          vendorName: payload.vendorName,
          vendorContactId: payload.vendorContactId,
          vendorUrl: payload.vendorUrl,
          vendorComments: payload.vendorComments,
          vendorActive: payload.vendorActive,
          phoneNumber: payload.phoneNumber,
        })
          .then(() => {
          })
          .then(() => {
            this.$bvToast.toast(`Your modifications have been saved.`, {
              title: "Vendor Edit",
              autoHideDelay: 5000,
              variant: "success",
            })
            this.originalRows = JSON.parse(JSON.stringify(this.coreRows))
            return this.loadCurrentRecord()
          })
          .catch((error) => {
            console.error("[VendorDetails.vue] Error saving vendor:", error)
            this.$bvToast.toast(`Failed to save modifications: ${error.message}`, {
              title: "Vendor Edit", 
              autoHideDelay: 5000,
              variant: "danger",
            })
          })
      }  else if (id === "backToSearchButton") {
        this.$router.push(`/vendor/search/`)
      } else if (id === "createIncidentButton") {
        if (this.email === "") {
          this.$store.dispatch("auth/retrieveEmail")
        }
        this.$bvModal.show("createIncidentModal")
      }
    },
  },
}
</script>

<template>
  <div id="VendorDetails">
    <DetailsTopBar
      :items="detailsTopBarItems"
      @buttonClick="onTopBarButtonClick"
    />
    <div class="PageContent">
      <div v-if="error.length > 0" class="errorPrompt">
        {{ error }}
      </div>
      <template v-else>
        <template v-if="loaded">
          <DetailsContainer 
            title="Vendor Details"
            :rows="coreRows" 
            :expanded="detailsExpanded"
            @expandToggle="detailsExpanded = !detailsExpanded"
          />
        </template>
        <div v-else class="errorPrompt">
          Unable to Retrieve Vendor Record
        </div>
      </template>
    </div>
    <IncidentModal
      :group="incidentGroup"
      subject="Vendor Update Request"
      :displayName="incDisplayName"
    />
  </div>
</template>

<style>
.errorPrompt {
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
</style>
