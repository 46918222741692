<script>
import Validation from "@/components/global/Validation"
import AdditionalInfo from "@/components/global/AdditionalInfo"
import CollectionTypePicker from "@/components/asset/CollectionTypePicker"
import SitePicker from "@/components/global/SitePicker"
import FacilityPicker from "@/components/global/FacilityPicker"
import ObservatoryPicker from "@/components/global/ObservatoryPicker"
import MultiValueText from "./MultiValueText.vue"
import ContractNumbersPicker from "@/components/publication/ContractNumbersPicker"
import SelectPicker from "@/components/global/SelectPicker"
import TextField from "@/components/global/TextField"
import ProductPickerWithIconVue from "@/components/asset/ProductPickerWithIcon"
import VendorPickerWithIconVue from "@/components/asset/VendorPickerWithIcon"
import ControlPicker from "../asset/AssetControlPicker.vue"
import TextArea from "@/components/global/TextArea"
import DatePicker from "@/components/global/DatePicker"
import DateTimePicker from "@/components/global/DateTimePicker"
import AssetDateTimePicker from "@/components/asset/AssetDateTimePicker"
import VendorPicker from "@/components/asset/VendorPicker"
import AssetLocationPicker from "@/components/asset/AssetLocationPicker"
import Checkbox from "@/components/global/Checkbox"
import PublicationTypePickerVue from "../publication/PublicationTypePicker.vue"
import PublicationStatusPicker from "../publication/PublicationStatusPicker.vue"
import AuthorStatusPicker from "../publication/AuthorStatusPicker.vue"
import ArticleStatusPicker from "../publication/ArticleStatusPicker.vue"
import ConferencePicker from "../publication/ConferencePicker.vue"
import JournalPicker from "../publication/JournalPicker.vue"
import PublisherPicker from "../publication/PublisherPicker.vue"
import PublicationReportTypePicker from "../publication/PublicationReportTypePicker.vue"
import AuthorRefPicker from "../publication/AuthorRefPicker.vue"
import TextDoi from "./TextDoi.vue"
import Alert from "@/components/global/Alert"
import Editor from "@/components/global/Editor"
import PeoplePicker from "@/components/global/PeoplePicker"
import AuthorPicker from "@/components/global/AuthorPicker"
import KeywordsPicker from "@/components/global/KeywordsPicker"
import InstPrimaryPubKeywordPicker from "../publication/InstPrimaryPubKeywordPicker"
import PublistCampaignKeywordPicker from "../publication/PublistCampaignKeywordPicker"
import KeywordTypePicker from "../publication/KeywordTypePicker"
import CampaignPicker from "@/components/fc/CampaignPicker"
import StatusPicker from "@/components/fc/StatusPicker"
import ManufacturerPicker from "@/components/asset/ManufacturerPicker"
import ProductPicker from "@/components/asset/ProductPicker"
import HazardPicker from "@/components/asset/HazardPicker"
import ItarEccnPicker from "@/components/asset/ItarEccnPicker"
import SensitivityRatingPicker from "@/components/itar/SensitivityRatingPicker"
import AssetCategoryPicker from "@/components/asset/AssetCategoryPicker"
import AssetStatusPicker from "@/components/asset/AssetStatusPicker"
import AssetLabPicker from "@/components/asset/AssetLabPicker"
import AssociationPicker from "../asset/AssociationPicker.vue"
import AssetPicker from "@/components/asset/AssetPicker"
import AssetLink from "@/components/asset/AssetLink"
import AssetTypePicker from "@/components/asset/AssetTypePicker"
import ArmLink from "@/components/global/ArmLink"
import FileLoader from "../global/FileLoader.vue"
import GuestInstrumentPicker from "@/components/fc/GuestInstrumentPicker"
import InstrumentClassPicker from "@/components/global/InstrumentClassPicker"
import CalibrationTypePicker from "@/components/calibration/CalibrationTypePicker"
import CalibrationPerformedByPicker from "@/components/calibration/CalibrationPerformedByPicker"
import CalibrationNotifyOptionsPicker from "@/components/calibration/CalibrationNotifyOptionsPicker"
import CalibrationIntervalPicker from "@/components/calibration/CalibrationIntervalPicker"
import CalibrationReminderPicker from "@/components/calibration/CalibrationReminderPicker"
import CalibrationResultsTable from "@/components/calibration/CalibrationResultsTable"
import CalibrationAssociationPicker from "@/components/calibration/CalibrationAssociationPicker"


export default {
  components: {
    Validation,
    TextDoi,
    DateTimePicker,
    AssetDateTimePicker,
    AdditionalInfo,
    PublicationStatusPicker,
    AuthorStatusPicker,
    CollectionTypePicker,
    SitePicker,
    AuthorRefPicker,
    MultiValueText,
    ContractNumbersPicker,
    PublicationReportTypePicker,
    FacilityPicker,
    ObservatoryPicker,
    SelectPicker,
    ArticleStatusPicker,
    ConferencePicker,
    JournalPicker,
    PublisherPicker,
    FileLoader,
    TextField,
    ControlPicker,
    ProductPickerWithIconVue,
    VendorPickerWithIconVue,
    PublicationTypePickerVue,
    TextArea,
    DatePicker,
    VendorPicker,
    AssetLocationPicker,
    Checkbox,
    Alert,
    AssociationPicker,
    Editor,
    PeoplePicker,
    AuthorPicker,
    KeywordsPicker,
    InstPrimaryPubKeywordPicker,
    PublistCampaignKeywordPicker,
    KeywordTypePicker,
    CampaignPicker,
    StatusPicker,
    ManufacturerPicker,
    ProductPicker,
    HazardPicker,
    ItarEccnPicker,
    SensitivityRatingPicker,
    AssetCategoryPicker,
    AssetStatusPicker,
    AssetLabPicker,
    AssetPicker,
    AssetLink,
    AssetTypePicker,
    ArmLink,
    GuestInstrumentPicker,
    InstrumentClassPicker,
    CalibrationTypePicker,
    CalibrationPerformedByPicker,
    CalibrationNotifyOptionsPicker,
    CalibrationIntervalPicker,
    CalibrationReminderPicker,
    CalibrationResultsTable,
    CalibrationAssociationPicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    activeOnly: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      fieldMap: {
        "authorrefpicker": "AuthorRefPicker",
        "multivalueinput": "MultiValueText",
        "contractnumberspicker": "ContractNumbersPicker",
        "textdoi": "TextDoi",
        "publicationreporttypepicker": "PublicationReportTypePicker",
        "publicationstatuspicker": "PublicationStatusPicker",
        "authorstatuspicker": "AuthorStatusPicker",
        "articlestatuspicker": "ArticleStatusPicker",
        "conferencepicker": "ConferencePicker",
        "journalpicker": "JournalPicker",
        "publisherpicker": "PublisherPicker",
        "text": "TextField",
        "publicationstypepicker": "PublicationTypePickerVue",
        "textarea": "TextArea",
        "datetimepicker": "DateTimePicker",
        "assetdatetimepicker": "AssetDateTimePicker",
        "link": "ArmLink",
        "fcstatus": "StatusPicker",
        "sitepicker": "SitePicker",
        "facilitypicker": "FacilityPicker",
        "selectpicker": "SelectPicker",
        "datepicker": "DatePicker",
        "checkbox": "Checkbox",
        "alert": "Alert",
        "editor": "Editor",
        "assetcontrolspicker": "ControlPicker",
        "peoplepicker": "PeoplePicker",
        "authorPicker": "AuthorPicker",
        "keywordspicker": "KeywordsPicker",
        "instprimarypubkeywordpicker": "InstPrimaryPubKeywordPicker",
        "publistcampaignkeywordpicker": "PublistCampaignKeywordPicker",
        "keywordtypepicker": "KeywordTypePicker",
        "observatorypicker": "ObservatoryPicker",
        "campaignpicker": "CampaignPicker",
        "assetlocationpicker": "AssetLocationPicker",
        "associationpicker" : "AssociationPicker",
        "fileloader": "FileLoader",
        "locationpicker": "AssetLocationPicker",
        "pickerwithicon": "ProductPickerWithIconVue",
        "vendorpickerwithicon": "VendorPickerWithIconVue",
        "manufacturerpicker": "ManufacturerPicker",
        "vendorpicker": "VendorPicker",
        "productpicker": "ProductPicker",
        "hazardpicker": "HazardPicker",
        "controlpicker": "ItarEccnPicker",
        "sensitivityratingpicker": "SensitivityRatingPicker",
        "assetcategorypicker": "AssetCategoryPicker",
        "assetstatuspicker": "AssetStatusPicker",
        "assetlabpicker": "AssetLabPicker",
        "assetpicker": "AssetPicker",
        "assetlink": "AssetLink",
        "assettypepicker": "AssetTypePicker",
        "collectiontypepicker": "CollectionTypePicker",
        "guestinstpicker": "GuestInstrumentPicker",
        "instclasspicker": "InstrumentClassPicker",
        "calibrationtypepicker": "CalibrationTypePicker",
        "calibrationperformedbypicker": "CalibrationPerformedByPicker",
        "calibrationnotifypicker": "CalibrationNotifyOptionsPicker",
        "calibrationintervalpicker": "CalibrationIntervalPicker",
        "calibrationreminderpicker": "CalibrationReminderPicker",
        "calibrationresultstable": "CalibrationResultsTable",
        "calibrationassociationpicker": "CalibrationAssociationPicker",
      },
      omitLabels: [
        "ProductPickerWithIconVue",
        "VendorPickerWithIconVue",
        "ArmLink",
      ],
    }
  },
  computed: {
    id() {
      return this.value?.data?.id
    },
    label() {
      return this.value?.data?.label
    },
    required() {
      return this.value?.validation?.rules?.required
    },
    type() {
      return this.fieldMap[this.value.type]
    },
    variant() {
      return this.value?.data?.variant
    },
    style() {
      return this.value?.data?.style
    },
    renderLabel() {
      return this.label && !this.omitLabels.includes(this.type)
    },
    additionalInfo() {
      return this.value?.data?.additionalInfo
    },
  },
  methods: {
    files(fileList) {
      this.$emit("files", fileList)
    },
    change(data) {
      switch (this.type.toLowerCase()) {
      case "sitepicker":
        this.$emit("onSitePickerChange", data)
        break
      case "guestinstpicker":
        this.$emit("onGuestInstrumentPickerChange", data)
        break
      case "calibrationintervalpicker":
        this.$emit("onCalibrationIntervalChange", data)
        break
      }
    },
    input() {
      switch (this.type) {
      case "facilitypicker":
        this.$emit("onFacilityPickerChange")
        break
      }
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="renderLabel"
      :for="id"
    >
      {{ label }}:
      <span
        v-if="required"
        class="required"
      >
        *
      </span>
    </label>
    <Validation
      :value="value"
      @validationErrors="$emit('validationErrors', $event)"
    >
      <component
        slot-scope="{ doValidation }"
        :is="type"
        v-model="value.data"
        :activeOnly="activeOnly"
        @doValidation="doValidation"
        @change="change"
        @input="input"
        @files="files"
      />
    </Validation>
    <AdditionalInfo
      v-if="additionalInfo && additionalInfo.value"
      :value="additionalInfo"
    />
  </div>
</template>

<style>
</style>
